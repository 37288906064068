import { IOperation } from "../Operations/types";

export enum Status {
    NONE = "NONE",
    SUCCESS = "SUCCESS",
    TIMEOUT = "TIMEOUT",
    CANCELED = "CANCELED",
    BAD_QUALITY = "BAD_QUALITY",
    MATCH_NOT_FOUND = "MATCH_NOT_FOUND",
    CAMERA_NOT_FOUND = "CAMERA_NOT_FOUND",
    FACE_NOT_FOUND = "FACE_NOT_FOUND",
    LIVENESS_CHECK_FAILED = "LIVENESS_CHECK_FAILED",
    BAD_SHARPNESS = "BAD_SHARPNESS",
    TOO_NOISY = "TOO_NOISY",
    BAD_LIGHTNING = "BAD_LIGHTNING",
    OCCLUSION = "OCCLUSION",
    BAD_POSE = "BAD_POSE",
    TOO_MANY_OBJECTS = "TOO_MANY_OBJECTS",
    MASK_DETECTED = "MASK_DETECTED",
    DUPLICATE_FOUND = "DUPLICATE_FOUND",
    DUPLICATE_ID = "DUPLICATE_ID",
    MOTION_BLUR = "MOTION_BLUR",
    COMPRESSION_ARTIFACTS = "COMPRESSION_ARTIFACTS",
    TOO_DISTANT = "TOO_DISTANT",
    TOO_CLOSE = "TOO_CLOSE",
    FAILED = "FAILED",
    ADJUSTING_QUALITY = "ADJUSTING_QUALITY",
    INTERNAL_ERROR = "INTERNAL_ERROR"
}

export interface ISubject extends IOperation {
    id: number,
    subjectId: string,
    operationId: number,
}

export interface ISubjectsFilter {
    subjectId: string,
    insertDateLte: string | undefined,
    insertDateGte: string | undefined,
    qualityLte: number | null,
    qualityGte: number | null,
    livenessScoreLte: number | null,
    livenessScoreGte: number | null,
    subjectFieldOrderBy: SubjectsFieldOrderBy,
    fieldsOrder: FieldsOrder
}

export enum SubjectsFieldOrderBy {
    SUBJECT_ID = "SUBJECT_ID",
    SUBJECT_DATE = "SUBJECT_DATE",
    SUBJECT_STATUS = "SUBJECT_STATUS",
    SUBJECT_LIVENESS_SCORE = "SUBJECT_LIVENESS_SCORE",
    SUBJECT_QUALITY = "SUBJECT_QUALITY"
}

export enum FieldsOrder {
    ASC = "ASC",
    DESC = "DESC"
}
