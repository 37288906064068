import { alpha, Toolbar, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import ActionConfirm from "../ActionConfirm";
import DoneIcon from '@material-ui/icons/Done';

interface IEnhancedTableToolbarProps {
    selected: number,
    onDeleteClick?: () => void,
    onDownloadClick: ((downloadOptions: any) => void),
    toolbarTitle: string,
    DownloadTooltip?: React.FC<any>,
}

const EnhancedTableToolbar = (props: IEnhancedTableToolbarProps) => {

    const { selected, onDeleteClick, onDownloadClick, toolbarTitle, DownloadTooltip } = { ...props }

    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(selected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {selected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {selected} selected
                </Typography>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {toolbarTitle}
                </Typography>
            )}
            {selected > 0 ? (
                <React.Fragment>
                    {onDeleteClick !== undefined? 
                        <Tooltip title="">
                            <ActionConfirm onConfirm={onDeleteClick} color="error" variant="contained" text="Delete" confirmIcon={<DoneIcon />} />
                        </Tooltip>
                        : null
                    }
                    {DownloadTooltip !== undefined?
                        <DownloadTooltip onDownloadClick={onDownloadClick}/>
                        : null
                    }
                </React.Fragment>
            ) : null}
        </Toolbar>
    );
}

export default EnhancedTableToolbar;
