import { FormControlLabel, styled, Switch } from '@material-ui/core';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import { IImageInfo } from '../../Store/Image/types';
import { IRectangle } from '../../Store/Operations/types';
import ImageCanvas from './ImageCanvas';


const StyledGridItem = styled(Grid)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
}));

interface ImageProp {
    id: keyof IImageInfo,
    displayName: string,
    formatter?: (value: any) => string
}

const DisplayImageProp: readonly ImageProp[] = [
    {
        id: 'date',
        displayName: 'Insert date',
        formatter: (value: number) => new Date(value).toUTCString()
    },
    {
        id: 'status',
        displayName: 'Status'
    },
    {
        id: 'yaw',
        displayName: 'Yaw'
    },
    {
        id: 'roll',
        displayName: 'Roll'
    },
    {
        id: 'pitch',
        displayName: 'Pitch'
    },
    {
        id: 'livenessTargetYaw',
        displayName: 'Liveness target yaw'
    },
    {
        id: 'livenessScore',
        displayName: 'Liveness score'
    },
    {
        id: 'quality',
        displayName: 'Quality'
    },
    {
        id: 'uuid',
        displayName: 'Uuid'
    },
    {
        id: 'age',
        displayName: 'Estimated age'
    }
];

const getObjectValueById = (prop: ImageProp, image: IImageInfo | undefined): string => {
    if (!image) return "";
    const value = image[prop.id]
    if (prop.formatter) {
        return prop.formatter(value);
    }
    const val = value?.toString();
    if(val !== undefined) {
        return val
    }
    return "";
}

interface ImageViewProps {
    getInfo: () => Promise<IImageInfo | undefined> | undefined
}

const ImageView: React.FC<ImageViewProps> = (props) => {
    const { getInfo } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [imageInfo, setImageInfo] = useState<IImageInfo>();
    const [showTokenImage, setShowTokenImage] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const info = await getInfo();
                if (info === undefined) return;
                setImageInfo(info);
                if (info.tokenImage) setShowTokenImage(true)
            } catch (error: any) {
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
        fetchData();
    }, [enqueueSnackbar, getInfo])

    return (
        <Grid container spacing={1} sx={{ justifyContent: "center", alignItems: "stretch" }}>
            <StyledGridItem item sm={12} md={8}>
                <FormControlLabel control={<Switch onChange={(event, checked) => setShowTokenImage(checked)} checked={showTokenImage} />} label={showTokenImage ? "Token image:" : "Image:"} />
                <ImageCanvas base64Image={showTokenImage ? imageInfo?.tokenImage : imageInfo?.image} rectangle={imageInfo?.boundingRect as IRectangle} showRect={!showTokenImage} />
            </StyledGridItem>
            <Grid item sm={12} md={4}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Property</TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {DisplayImageProp.map(displayProp => (
                            <TableRow key={displayProp.id}>
                                <TableCell>{displayProp.displayName}</TableCell>
                                <TableCell align="right">{getObjectValueById(displayProp, imageInfo)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}

export default ImageView
