import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Input, InputLabel, TextField, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import { createSubject } from '../../Store/Subjects/actions';
import { Status } from '../../Store/Subjects/types';
import { Buffer } from 'buffer';

interface IAddSubjectDialogProps {
    open: boolean,
    onClose: () => void
}

const readFile = async (file: File): Promise<Uint8Array> => {
    return new Promise<Uint8Array>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            let result = new Uint8Array(reader.result as ArrayBuffer);
            resolve(result);
        }
        reader.onerror = (event) => {
            reject(reader.error)
        }
        reader.readAsArrayBuffer(file);
    })
}

const AddSubjectDialog: React.FC<IAddSubjectDialogProps> = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const [image, setImage] = useState("");

    useEffect(() => {
        setImage("");
    }, [props])


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const subjectId = new FormData(event.currentTarget).get("subject-id") as string;
        const _image = new FormData(event.currentTarget).get("subject-image") as File;
        const nTemplate = new FormData(event.currentTarget).get("subject-n-template") as File;
        const fvTemplate = new FormData(event.currentTarget).get("subject-fv-template") as File;

        if (!subjectId) {
            enqueueSnackbar("Subject id must be specified", { variant: 'warning' });
            return;
        }

        const imageContent = await readFile(_image);
        const nTemplateContent = await readFile(nTemplate);
        const fvTemplateContent = await readFile(fvTemplate);

        if (nTemplateContent.length === 0 && imageContent.length === 0 && fvTemplateContent.length === 0) {
            enqueueSnackbar("At least one of subject image, N Template or FV Template must be added", { variant: 'warning' });
            return;
        }

        try {
            const imageBase64 = Buffer.from(imageContent).toString('base64');
            const nTemplateBase64 = Buffer.from(nTemplateContent).toString('base64');
            const fvTemplateBase64 = Buffer.from(fvTemplateContent).toString('base64');

            createSubject(subjectId, imageBase64, nTemplateBase64, fvTemplateBase64).then((subject) => {
                if (subject.status === Status.SUCCESS) {
                    enqueueSnackbar("Subject created successfully", { variant: 'info' });
                } else {
                    enqueueSnackbar("Failed to create subject: " + subject.status, { variant: 'error' });
                }
            }).catch(error => {
                enqueueSnackbar(error.message, { variant: 'error' });
            }).finally(() => props.onClose());
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
        props.onClose();
    }

    const onImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = (event.currentTarget as HTMLInputElement).files;
        if (files) {
            const file = files[0] as File;
            const test = URL.createObjectURL(file);
            setImage(test);
        }
    }

    return (
        <Dialog
            maxWidth="md"
            scroll="body"
            fullWidth
            open={props.open}
            onClose={props.onClose}
        >
            <Box component="form" onSubmit={handleSubmit} noValidate >
                <DialogTitle>Create new subject</DialogTitle>
                <DialogContent>
                    <Grid container style={{ padding: "1rem 0" }} spacing={1}>
                        <Grid item xs={12} sm={6} style={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <TextField
                                sx={{margin: "none"}}
                                autoComplete="off"
                                id="subject-id"
                                name="subject-id"
                                label="Subject ID"
                            />
                            <InputLabel>Subject image</InputLabel>
                            <Input
                                id="subject-image"
                                name="subject-image"
                                type="file"
                                onChange={onImageSelect}
                                inputProps={{
                                    accept: 'image/png, image/jpeg, image/jpg, image/tiff, image/tif, image/bmp'
                                }}
                            />
                            <InputLabel>Subject N Template</InputLabel>
                            <Input
                                id="subject-n-template"
                                name="subject-n-template"
                                type="file"
                            />
                            <InputLabel>Subject FV Template</InputLabel>
                            <Input
                                id="subject-fv-template"
                                name="subject-fv-template"
                                type="file"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {image ? <img src={image} style={{ maxHeight: "250px", maxWidth: "100%" }} alt="" /> :
                                (<Typography>Image preview</Typography>)}

                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="contained">
                        Add
                    </Button>
                </DialogActions>
            </Box>
        </Dialog >
    )
}

export default AddSubjectDialog
