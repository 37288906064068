import { Button, Container, Grid, Paper, styled, TextField, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { IOperationFilter, LivenessMode, Status, OperationType, OperationsFieldOrderBy } from "../../Store/Operations/types";
import DateTimeRange from "../Misc/DateTimeRange";
import MultipleSelect from "../Misc/MultipleSelect";
import { FieldsOrder } from "../../Store/Subjects/types";

const StyledButton = styled(Button)({
    margin: "0 0.5rem"
});

interface IOperationFilterProps {
    onFilterApply: (filter: IOperationFilter) => void
}

interface IDateRange {
    from: Date | null,
    to: Date | null
}

const OperationFilter: React.FC<IOperationFilterProps> = (props) => {

    const { enqueueSnackbar } = useSnackbar();

    const [operationStatus, setOperationStatus] = useState<string[]>([]);
    const [operationTypes, setOperationTypes] = useState<string[]>([]);
    const [operationLivenessModes, setOperationLivenessModes] = useState<string[]>([]);
    const [insertDate, setInsertDate] = useState<IDateRange>({from: null, to: null})
    const [operationUuid, setOperationUuid] = useState("")
    const [uuidRegexValid, setUuidRegexValid] = useState(true);

    const submitFilter = () => {
        console.log("filter submited")
    }

    const getOperationStatus = (): string[] => {
        let values = Object.keys(Status)
        return values
    }

    const getOperationTypes = (): string[] => {
        let values = Object.keys(OperationType)
        return values
    }

    const getOperationLivenessMode = (): string[] => {
        let values = Object.keys(LivenessMode)
        return values
    }

    const handleStatusSelectionChange = (event: any) => {
        const value = event.target.value;
        setOperationStatus(typeof value === 'string' ? value.split(',') : value)
    }

    const handleTypeSelectionChange = (event: any) => {
        const value = event.target.value;
        setOperationTypes(typeof value === 'string' ? value.split(',') : value)
    }

    const handleLivenessModeSelectionChange = (event: any) => {
        const value = event.target.value;
        setOperationLivenessModes(typeof value === 'string' ? value.split(',') : value)
    }

    const handleInsertDateChange = (source: "from" | "to", value: Date | null) => {
        switch (source) {
            case 'from':
                setInsertDate(prev => ({ ...prev, from: value }))
                break;
            case 'to':
                setInsertDate(prev => ({ ...prev, to: value }))
                break;
        }
    }

    const handleUuidChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setOperationUuid(event.target.value)
        try {
            new RegExp(event.target.value);
        } catch(e) {
            setUuidRegexValid(false)
            return;
        }
        setUuidRegexValid(true)
    }

    const resetFilter = () => {
        setInsertDate({ from: null, to: null })
        setOperationStatus([]);
        setOperationTypes([]);
        setOperationLivenessModes([]);
        setOperationUuid("")
    }

    const onFilterApply = () => {
        let filter: IOperationFilter = {
            dateLte: insertDate.from?.toISOString(),
            dateGte: insertDate.to?.toISOString(),
            statuses: operationStatus.map(x => Status[x as keyof typeof Status]),
            operationTypes: operationTypes.map(x => OperationType[x as keyof typeof OperationType]),
            livenessModes: operationLivenessModes.map(x => LivenessMode[x as keyof typeof LivenessMode]),
            uuid: operationUuid,
            operationsFieldOrderBy: OperationsFieldOrderBy.OPERATION_DATE,
            fieldsOrder: FieldsOrder.ASC
        }
        
        if (insertDate.from && insertDate.to &&
            (insertDate.to.getTime() < insertDate.from.getTime())) {
            enqueueSnackbar("Time range FROM threshold cannot be greater than TO", { variant: 'warning' });
            return;
        }

        props.onFilterApply(filter)
    }

    return (
        <Container maxWidth="lg" sx={{ marginBottom: "1rem" }}>
            <Paper elevation={10}>
                <Grid container onSubmit={submitFilter} spacing={1} sx={{ padding: "1rem" }}>
                    <Grid item xs={12} md={6}>
                        <MultipleSelect
                            label="Operation types"
                            values={getOperationTypes()}
                            selectedValues={operationTypes}
                            onSelectionChange={handleTypeSelectionChange}
                            inputLabel="Types"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MultipleSelect
                            label="Liveness mode"
                            values={getOperationLivenessMode()}
                            selectedValues={operationLivenessModes}
                            onSelectionChange={handleLivenessModeSelectionChange}
                            inputLabel="Liveness mode"
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <MultipleSelect
                            label="Operation status"
                            values={getOperationStatus()}
                            selectedValues={operationStatus}
                            onSelectionChange={handleStatusSelectionChange}
                            inputLabel="Status"
                        />
                    </Grid>

                    <Grid item sm={12} md={4} >
                        <DateTimeRange
                            label="Insert date range"
                            from={insertDate.from}
                            to={insertDate.to}
                            onFromDateChange={(value) => handleInsertDateChange("from", value)}
                            onToDateChange={(value) => handleInsertDateChange("to", value)}
                        />
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <Typography sx={{ marginBottom: "-5px" }}>
                            Operation UUID
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField
                            fullWidth
                            label="Regex"
                            value={operationUuid}
                            onChange={(event) => handleUuidChange(event)}
                            error={!uuidRegexValid}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ padding: "1rem 2rem", display: "flex", justifyContent: "flex-end" }}>
                        <StyledButton
                            onClick={() => resetFilter()}
                        >
                            Reset
                        </StyledButton>
                        <StyledButton
                            onClick={() => onFilterApply()}
                            variant="contained"
                        >
                            Filter
                        </StyledButton>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}

export default OperationFilter
