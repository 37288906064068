import { Box, Paper, styled, Typography } from "@material-ui/core";
import React from "react";
import { IDongle, IInternetLicense } from "../../Store/Licensing/types"
import Dongle from "./Dongle";
import InternetLicense from "./InternetLicense";

const Container = styled(Paper)(({ theme }) => ({
    display: "flex",
    padding: theme.spacing(1, 0),
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: theme.palette.secondary.main
}));

const Font = styled(Typography)(({ theme }) => ({
    display: "flex",
    padding: "0 0.5rem",
    fontWeight: 300,
    color: "#e5e5e5",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
        fontSize: "1rem"
    }
}));

interface IStyledStatus {
    status: String
}

const StyledStatus: React.FC<IStyledStatus> = (status) => {
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            boxShadow: "0px 1px 1px 1px rgb(0 0 0 / 20%), 0px 6px 4px 1px rgb(0 0 0 / 14%)",
            backgroundColor: status.status === "OK" ? '#2f7868' : "#d32f2f"
        }}>
            <Font sx={{ fontWeight: 400 }}>
                {status.status}
            </Font>
        </Box>
    );
}

interface ILicenseContainerProps {
    dongles: IDongle[]
    internetLicenses: IInternetLicense[]
}

const LicenseContainer: React.FC<ILicenseContainerProps> = ({dongles, internetLicenses}) => {
    return (
        <React.Fragment>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 1, marginBottom: "1rem" }}>
                {dongles.map(dongle => {
                    return (
                        <Container elevation={10}>
                            <Dongle 
                                key={Math.random().toString()} 
                                dongle={dongle} 
                                Font={Font}
                            />
                            <StyledStatus status={dongle.status}/>
                        </Container>
                    )
                })}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 1, marginBottom: "1rem" }}>
                {internetLicenses.map(internetLicense => {
                    return (
                        <Container elevation={10}>
                            <InternetLicense 
                                key={Math.random().toString()} 
                                internetLicense={internetLicense} 
                                Font={Font}
                            />
                            <StyledStatus status={internetLicense.internetStatus}/>
                        </Container>
                    )
                })}
            </Box>
        </React.Fragment>
    );
}

export default LicenseContainer