import { getFullApiUrl } from "../../config/axios";
import Axios, { AxiosRequestConfig } from "axios";
import qs from "querystring"
import { IImage } from "./types";
import fileDownload from "js-file-download";
import { ImageDownloadOption } from "../../components/ImageView/ImageInfoDownload";
import { ISubject } from "../Subjects/types";
import { IOperation } from "../Operations/types";

const convertToInLineArray = (params: any): string => {
    const filterNonNull = (obj: Object) => {
        return Object.fromEntries(Object.entries(obj).filter(([k, v]) => v));
    }
    return qs.stringify(filterNonNull(params))
};

export const getImage = async (imageId: number) => {
    let config: AxiosRequestConfig = {
        params: {
            id: imageId
        },
        paramsSerializer: convertToInLineArray
    }
    const response = await Axios.get<IImage>(getFullApiUrl() + "/images/id/", config);
    return response.data;
}

export const downloadImageInfoFromSubject = async (subject: ISubject, downlaodOptions: ImageDownloadOption[]) => {
    let config: AxiosRequestConfig = {
        params: {
            downloadOptions: downlaodOptions,
        },
        responseType: "blob",
        paramsSerializer: convertToInLineArray
    }
    Axios.get(getFullApiUrl() + "/images/subject/" + subject.id + "/download", config)
        .then(res => {
            fileDownload(res.data, subject.id + "_" + subject.subjectId + ".zip")
        });
}

export const downloadImageInfoFromOperation = async (operation: IOperation, downlaodOptions: ImageDownloadOption[]) => {
    let config: AxiosRequestConfig = {
        params: {
            downloadOptions: downlaodOptions,
        },
        responseType: "blob",
        paramsSerializer: convertToInLineArray
    }
    Axios.get(getFullApiUrl() + "/images/operation/" + operation.id + "/download", config)
        .then(res => {
            fileDownload(res.data, operation.id + ".zip")
        });
}

export const downloadImageInfoFromSubjectBulk = async (ids: number[], downloadOptions: ImageDownloadOption[]) => {
    let config: AxiosRequestConfig = {
        params: {
            downloadOptions: downloadOptions,
            ids: ids
        },
        responseType: "blob",
        paramsSerializer: convertToInLineArray
    }
    Axios.get(getFullApiUrl() + "/images/subjects/download", config)
        .then(res => {
            fileDownload(res.data, "subjects.zip")
        });
}
