import React, { useState } from 'react'
import { Box, Container, Grid, IconButton, styled, Theme, useMediaQuery } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../Logo/Logo'
import { MenuItems } from '../../config/menuItems'
import MenuItem, { IMenuItem } from './MenuItem'
import { RouteComponentProps, withRouter } from 'react-router'
import MobileNavigation from './MobileNavigation';
import Authentication from '../../Store/Authentication/AuthenticationStore';
import { useSnackbar } from 'notistack';

const ReactiveLogoContainer = styled(Grid)(({ theme }) => ({
    height: "inherit",
    padding: "0.5rem 0",
    flex: 1
}));

const ReactiveMenuContainer = styled(Grid)(({ theme }) => ({
    height: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly"
}));

const ReactiveNavigationContent = styled(Grid)(({ theme }) => ({
    height: "inherit",
    justifyContent: "space-around",
    alignItems: "flex-start",
    flexWrap: "nowrap",
    [theme.breakpoints.up("lg")]: {
        padding: "0 4.5rem"
    },
    [theme.breakpoints.down("lg")]: {
        padding: "0 2.5rem"
    },
    [theme.breakpoints.down("md")]: {
        padding: "0 1.5rem"
    },
    [theme.breakpoints.down("sm")]: {
        padding: "0"
    }
}));

const ReactiveNavigationBar = styled(Box)(({ theme }) => ({
    height: "80px",
    width: "100%",
    zIndex: 5,
    top: 0,
    position: "sticky",
    background: "#2F7868",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    [theme.breakpoints.down("sm")]: {
        height: "60px"
    },
    [theme.breakpoints.down("md")]: {
        paddingRight: "0px"
    },
    paddingRight: "17px"
}));

const Navigation: React.FC<RouteComponentProps<any, any, any>> = (props) => {
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const showMobileNav = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const menuItems = [...MenuItems];

    const navPicker = () => {
        if (showMobileNav) {
            return (
                <IconButton sx={{ color: "white" }} onClick={() => setDrawerOpen(true)}>
                    <MenuIcon fontSize="large" />
                </IconButton>
            )
        } else {
            if (isDrawerOpen)
                setDrawerOpen(false);
            return (
                <React.Fragment>
                    {menuItems.map((x, i) => <MenuItem {...x} selected={isItemSelected(i)} key={Math.random().toString()} />)}
                </React.Fragment>
            )
        }
    }

    const isItemSelected = (menuIndex: number): boolean => {
        return menuItems[menuIndex].path === props.location.pathname
    }

    const handleLogout = () => {

        Authentication.logout()
            .then(res => {
                setDrawerOpen(false);
            })
            .catch(err => enqueueSnackbar(err.message, { variant: 'error' }));
    }

    const LogoutMenuItem: IMenuItem = {
        name: "Logout",
        type: "contained",
        onMenuItemClick: handleLogout
    };
    menuItems.push(LogoutMenuItem);

    return (
        <React.Fragment>
            {isDrawerOpen ?
                <MobileNavigation menuItems={menuItems} currentPath={props.location.pathname} onNavClose={() => setDrawerOpen(false)} />
                : null}
            <ReactiveNavigationBar>
                <ReactiveNavigationContent container spacing={0}>
                    <ReactiveLogoContainer item>
                        <Logo nav />
                    </ReactiveLogoContainer>
                    <ReactiveMenuContainer item>
                        {navPicker()}
                    </ReactiveMenuContainer>
                </ReactiveNavigationContent>
            </ReactiveNavigationBar>
            <Container maxWidth="xl" sx={{marginTop: 5, display: isDrawerOpen ? 'none' : 'block' }}>
                {props.children}
            </Container>
        </React.Fragment>
    )
}

export default withRouter(Navigation)
