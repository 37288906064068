import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'
import { useEffect } from 'react'
import DongleUpdate from '../../components/Licensing/DongleUpdate';
import LicenseContainer from '../../components/Licensing/LicenseContainer';
import UsageRow from '../../components/Licensing/UsageRow';
import { getDongles, getInternetLicenses, getLicensingUsage, getToken, getUpdateStatus, updateDongle } from '../../Store/Licensing/actions';
import { IDongle, IInternetLicense, IStatus, IUsage, LicensingStatusUpdate } from '../../Store/Licensing/types';

const Licensing: React.FC = () => {

    const { enqueueSnackbar } = useSnackbar();
    const [internetLicenses, setInternetLicense] = useState<IInternetLicense[]>([]);
    const [dongles, setDongles] = useState<IDongle[]>([]);
    const [usage, setUsage] = useState<IUsage[]>([]);
    const [token, setToken] = useState<string>("");
    const [updateStatus, setUpdateStatus] = useState<IStatus>({status: LicensingStatusUpdate.NONE});


    useEffect(() => {
        const fetchData = async () => {
            getInternetLicenses().then(res => setInternetLicense(res)).catch(error => enqueueSnackbar(error.message, { variant: 'error' }));
            getDongles().then(res => setDongles(res)).catch(error => enqueueSnackbar(error.message, { variant: 'error' }));
            getLicensingUsage().then(res => setUsage(res)).catch(error => enqueueSnackbar(error.message, { variant: 'error' }));
            getUpdateStatus().then(( status ) => setUpdateStatus(status)).catch(error => enqueueSnackbar(error.message, { variant: 'error' }));
            getToken().then(res => setToken(res)).catch(error => enqueueSnackbar(error.message, { variant: 'error' }));
        };
        fetchData();
    }, [enqueueSnackbar])

    const handleDongleUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget).get("dongle-update") as File;
        const content = await data.text();
        if (content) {
            try {
                var { status } = await updateDongle(content);
                setUpdateStatus({status: status})
                const interval = setInterval(() => {
                    getUpdateStatus().then(({ status }) => {
                        setUpdateStatus({status:status});
                        if (status !== LicensingStatusUpdate.IN_PROGRESS && status !== LicensingStatusUpdate.QUEUED ) {
                            if (status === LicensingStatusUpdate.DONE)
                                enqueueSnackbar("Dongle updated finished succesfully", {variant: 'success'})
                            if (status === LicensingStatusUpdate.FAILED)
                                enqueueSnackbar("Dongle updated failed", {variant: "error"})
                            clearInterval(interval)
                        }
                    }).catch(error => {
                        enqueueSnackbar(error.message, { variant: 'error' });
                        clearInterval(interval);
                    });
                }, 1000)
                if (status === LicensingStatusUpdate.QUEUED) {
                    enqueueSnackbar("Dongle update has been queued", { variant: 'info' });
                } else {
                    enqueueSnackbar("Dongle update status: " + status, { variant: 'info' });
                }
            } catch (error: any) {
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        } else {
            enqueueSnackbar("Please select dongle update file", { variant: 'warning' });
        }
    }

    return (
        <React.Fragment>
            <LicenseContainer dongles={dongles} internetLicenses={internetLicenses}/>
            <Container maxWidth="md">
                <Paper elevation={10} sx={{ padding: "1rem", display: "flex", flexDirection: "column" }}>
                    <Typography variant="h6" sx={{ margin: "8px 0" }}>License usage</Typography>
                    <TableContainer sx={{ overflowY: "auto" }}>
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Operation</TableCell>
                                    <TableCell colSpan={2}>Usage</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {usage.map(usage => {
                                    return <UsageRow key={Math.random().toString()} usage={usage} />
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography sx={{ marginTop: "1rem", marginBottom: "0.5rem" }}>Licensing token: {token}</Typography>
                </Paper>
                {internetLicenses.length === 0? 
                    <DongleUpdate updateStatus={updateStatus} handleDongleUpdate={handleDongleUpdate}/>
                    : null
                }
            </Container>
        </React.Fragment >
    )
}

export default Licensing
