import React from 'react'
import Navigation from '../../components/Navigation/Navigation'

const Layout: React.FC = (props) => {

    return (
        <React.Fragment>
            <Navigation>
                {props.children}
            </Navigation>
        </React.Fragment>

    )
}

export default Layout
