import { FieldsOrder } from "../Subjects/types"

export interface IOperation {
    id: number,
    operationType: OperationType,
    status: Status,
    livenessMode: LivenessMode,
    date: number,
    uuid: string,
    imageId: number,
    yaw: number,
    roll: number,
    pitch: number,
    livenessTargetYaw: number,
    livenessScore: number,
    quality: number,
    boundingRect: IRectangle,
    age: number,
}

export interface IOperationFilter {
    dateLte: string | undefined,
    dateGte: string | undefined,
    statuses: Status[],
    operationTypes: OperationType[],
    livenessModes: LivenessMode[],
    uuid: string | undefined,
    operationsFieldOrderBy: OperationsFieldOrderBy,
    fieldsOrder: FieldsOrder
}

export enum OperationsFieldOrderBy {
    OPERATION_DATE = "OPERATION_DATE",
    OPERATION_STATUS = "OPERATION_STATUS",
    OPERATION_LIVENESS_MODE = "OPERATION_LIVENESS_MODE",
    OPERATION_TYPE = "OPERATION_TYPE",
    OPERATION_UUID = "OPERATION_UUID",
}

export enum Status {
    NONE = "NONE",
    SUCCESS = "SUCCESS",
    TIMEOUT = "TIMEOUT",
    CANCELED = "CANCELED",
    BAD_QUALITY = "BAD_QUALITY",
    MATCH_NOT_FOUND = "MATCH_NOT_FOUND",
    CAMERA_NOT_FOUND = "CAMERA_NOT_FOUND",
    FACE_NOT_FOUND = "FACE_NOT_FOUND",
    LIVENESS_CHECK_FAILED = "LIVENESS_CHECK_FAILED",
    BAD_SHARPNESS = "BAD_SHARPNESS",
    TOO_NOISY = "TOO_NOISY",
    BAD_LIGHTNING = "BAD_LIGHTNING",
    OCCLUSION = "OCCLUSION",
    BAD_POSE = "BAD_POSE",
    TOO_MANY_OBJECTS = "TOO_MANY_OBJECTS",
    MASK_DETECTED = "MASK_DETECTED",
    DUPLICATE_FOUND = "DUPLICATE_FOUND",
    DUPLICATE_ID = "DUPLICATE_ID",
    MOTION_BLUR = "MOTION_BLUR",
    COMPRESSION_ARTIFACTS = "COMPRESSION_ARTIFACTS",
    TOO_DISTANT = "TOO_DISTANT",
    TOO_CLOSE = "TOO_CLOSE",
    FAILED = "FAILED",
    INITIALIZED = "INITIALIZED",
    JANUS_UNKNOWN_ERROR = "JANUS_UNKNOWN_ERROR",
    JANUS_PLUGIN_ERROR = "JANUS_PLUGIN_ERROR",
    JANUS_NOT_FOUND = "JANUS_NOT_FOUND",
    JANUS_BAD_REQUEST = "JANUS_BAD_REQUEST",
    JANUS_ICE_FAILED = "JANUS_ICE_FAILED",
    ADJUSTING_QUALITY = "ADJUSTING_QUALITY",
    INTERNAL_ERROR = "INTERNAL_ERROR",
}

export enum OperationType {
    UNSPECIFIED = "UNSPECIFIED",
    VERIFY = "VERIFY",
    CHECK_LIVENESS = "CHECK_LIVENESS",
    ENROLL = "ENROLL",
}

export enum LivenessMode {
    DO_NOT_DETECT = "DO_NOT_DETECT",
    PASSIVE = "PASSIVE",
    ACTIVE = "ACTIVE",
    SIMPLE = "SIMPLE",
    CUSTOM = "CUSTOM",
    PASSIVE_WITH_BLINK = "PASSIVE_WITH_BLINK",
}

export interface IRectangle {
    x: number,
    y: number,
    width: number,
    height: number
}
