import React from 'react'
import { Grid, TextField, Typography } from '@material-ui/core'
import { DateTimePicker, LocalizationProvider } from '@material-ui/lab'
import AdapterDateFns from '@material-ui/lab/AdapterDateFns'

interface IDateTimeRangeProps {
    label: string,
    from?: Date | null,
    to?: Date | null,
    onFromDateChange: (date: Date | null) => void,
    onToDateChange: (date: Date | null) => void
}

const DateTimeRange: React.FC<IDateTimeRangeProps> = (props) => {
    return (
        <Grid container spacing={1} sx={{ maxWidth: "500px" }}>
            <Grid item xs={12}>
                <Typography sx={{ marginBottom: "-5px" }}>
                    {props.label}
                </Typography>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid item xs={6}>
                    <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="From"
                        value={props.from}
                        onChange={props.onFromDateChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="To"
                        value={props.to}
                        onChange={props.onToDateChange}
                    />
                </Grid>
            </LocalizationProvider>
        </Grid>
    )
}

export default DateTimeRange
