import axios from "axios";
import history from "../../config/history";
import { login, logout } from "./actions";
import { AuthenticationData } from "./types";

class AuthenticationClass {

    authentication: AuthenticationData | null = null;
    authDataKey = "fvManagementAuthData";

    constructor() {
        this.authentication = this.loadAuthentication();
    }

    saveAuthentication() {
        const serializedAuthentication = JSON.stringify(this.authentication);
        localStorage.setItem(this.authDataKey, serializedAuthentication);
    }

    loadAuthentication() {
        const unserializedAuthentication = localStorage.getItem(this.authDataKey);
        if (unserializedAuthentication) {
            const authentication: AuthenticationData = JSON.parse(unserializedAuthentication);
            return authentication;
        }
        return null;
    }

    clearAuthentication() {
        this.authentication = null;
        localStorage.removeItem(this.authDataKey);
    }

    isAuthenticated() {
        return this.authentication != null;
    }

    async logout() {
        await logout();
        this.clearAuthentication();
        history.push("/login");
    }

    async Authenticate(username: string, password: string) {
        try {
            var authData = await login(username, password);
            var authority = authData.data && authData.data[0].authority;
            if (authority === "ROLE_USER") {
                throw new Error("Only administrators are allowed")
            }
            this.authentication = {
                authority: authority as string,
                username: username
            }
            this.saveAuthentication();
        }
        catch (error: any) {
            if (axios.isAxiosError(error)) {
                switch (error.response?.status) {
                    case 401:
                        throw new Error("Bad username or password");
                }
            }
            throw error;
        }
    }

}

const Authentication = new AuthenticationClass();
export default Authentication;