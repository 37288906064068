import React, { useCallback, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { Typography, ClickAwayListener, Box, Table, TableHead, TableRow, TableCell, TableBody, Input, InputAdornment, IconButton, Paper, Button } from '@material-ui/core'
import { getSettings, setSetting, resetSettings } from '../../Store/Management/actions';
import ActionConfirm from '../Misc/ActionConfirm';
import { ISetting } from '../../Store/Management/types';
import AddSettingsDialog from './AddSettingsDialog';

const SettingsView: React.FC = () => {

    const { enqueueSnackbar } = useSnackbar();

    const [settings, setSettings] = useState<ISetting[]>([]);
    const [selected, setSelected] = useState(-1);
    const [changed, setChanged] = useState(false);
    const [dialog, setDialog] = useState(false);


    const fetchSettings = useCallback(async () => {
        try {
            const settingsData = await getSettings();
            setSettings(settingsData);
            setChanged(false);
        } catch (err) {
            // enqueueSnackbar(err.message, { variant: 'error' });
        }
    }, []);

    useEffect(() => {
        fetchSettings();
    }, [fetchSettings]);

    const saveSelectedSetting = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        let array = [...settings];
        let tempSetting = array[selected]
        let newValue = data.get(tempSetting.key as string) as string | null;
        if (newValue !== tempSetting.value) {
            tempSetting.value = newValue === '' ? null : newValue;
            setChanged(true);
        }
        setSelected(-1);
        setSettings(array);
    }

    const handleSettingsSave = async () => {
        try {
            for (var setting of settings) {
                await setSetting(setting.key, setting.value);
            }
            enqueueSnackbar("Settings saved successfully", { variant: 'info' });
            fetchSettings();
        } catch (err: any) {
            enqueueSnackbar(err.message, { variant: 'error' });
        }
    }

    const handleSettingsReset = async () => {
        try {
            await resetSettings();
            enqueueSnackbar("Settings have been reset to the default values", { variant: 'info' });
            fetchSettings();
        } catch (err: any) {
            enqueueSnackbar(err.message, { variant: 'error' });
        }
    }

    const handleSettingsDialogClose = () => {
        setDialog(false);
        fetchSettings();
    }

    const onSettingDelete = (index: number) => {
        let setting = settings[index];
        setSetting(setting.key, null).then(res => {
            enqueueSnackbar("Setting deleted successfully", { variant: 'info' });
            fetchSettings();
        }).catch(err => {
            enqueueSnackbar(err.message, { variant: 'error' });
        });
    }

    return (
        <Paper sx={{ padding: "1rem", marginBottom: "1rem", display: "flex", justifyContent: "center", flexDirection: "column" }} elevation={10}>
            <Typography variant="h6" sx={{ margin: 1 }}>Settings</Typography>
            <ClickAwayListener onClickAway={() => setSelected(-1)}>
                <Box
                    onSubmit={saveSelectedSetting}
                    component="form"
                    autoComplete="off"
                    noValidate
                    style={{ overflowX: "auto" }}
                >
                    <Table size="small" sx={{ marginBottom: "1rem" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell width="30%">Name</TableCell>
                                <TableCell width="60%" align="right">Value</TableCell>
                                <TableCell width="10%" align="right" />
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {settings.map((x, i) => (
                                <TableRow
                                    key={Math.random().toString()}
                                >
                                    <TableCell width="30%">{x.key}</TableCell>
                                    <TableCell width="60%" align="right">
                                        {i === selected ? (
                                            <Input
                                                id={x.key as string}
                                                name={x.key as string}
                                                size="small"
                                                defaultValue={x.value}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="save setting"
                                                            type="submit"
                                                        >
                                                            <SaveIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        ) : x.value}
                                    </TableCell>
                                    <TableCell width="10%" align="right">
                                        <Box sx={{ display: "inline-flex" }}>
                                            {i === selected ?
                                                <IconButton size="small" onClick={() => setSelected(-1)}>
                                                    <CloseIcon />
                                                </IconButton>
                                                :
                                                <IconButton size="small" onClick={() => setSelected(i)}>
                                                    <EditIcon />
                                                </IconButton>}
                                            <ActionConfirm
                                                compact
                                                onConfirm={() => onSettingDelete(i)}
                                                color="error"
                                                variant="contained"
                                                text="Delete"
                                                confirmIcon={<DoneIcon />}
                                                buttonIcon={<DeleteIcon />}
                                            />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </ClickAwayListener>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                <ActionConfirm onConfirm={handleSettingsReset} color="primary" variant="text" text="Reset to default" confirmIcon={<DoneIcon />} />
                <Button onClick={() => setDialog(true)}> Add new</Button>
                <ActionConfirm disabled={!changed} onConfirm={handleSettingsSave} color="primary" variant="contained" text="Save" confirmIcon={<DoneIcon />} />
            </Box>
            <AddSettingsDialog
                open={dialog}
                onClose={handleSettingsDialogClose}
            />
        </Paper>
    )
}

export default SettingsView
