import React, { useState } from 'react'
import { Checkbox, IconButton, LinearProgress, Table, TableBody, TableCell, TableContainer, TableRow} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfiniteScroll from 'react-infinite-scroller';
import { ImageDownloadOption } from '../../ImageView/ImageInfoDownload';
import { useSnackbar } from 'notistack';
import EnhancedTableToolbar from './EnchancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import { downloadImageInfoFromSubjectBulk } from '../../../Store/Image/actions';
import { IOperationFilter, OperationsFieldOrderBy } from '../../../Store/Operations/types';
import { FieldsOrder, ISubjectsFilter, SubjectsFieldOrderBy } from '../../../Store/Subjects/types';

export type Order = 'asc' | 'desc';
export type OrderByKey = 'id' | 'SUBJECT_ID' | 'SUBJECT_LIVENESS_SCORE' | 'SUBJECT_QUALITY' 
        | 'SUBJECT_STATUS' | 'SUBJECT_DATE' | 'SUBJECT_LIVENESS_MODE' | 'OPERATION_TYPE' | 'UUID' | 'OPERATION_DATE' | 'OPERATION_STATUS' | 'OPERATION_LIVENESS_MODE';

export interface HeadCell {
    disablePadding: boolean;
    id: OrderByKey;
    label: string;
    align: 'left' | 'right';
}

interface IDataTableProps {
    data: any,
    operationFilter?: IOperationFilter | null,
    subjectFilter?: ISubjectsFilter | null,
    onFilterChange?: (filter: any) => void,
    hasMore: boolean,
    onRowClick?: (subject: any) => void,
    onLoadMore: (page: number) => Promise<any>,
    headCells: HeadCell[],
    tableCells: string[],
    title: string,
    dateName: string,
    DownloadTooltip?: React.FC<any>,
    handleDelete?: (selected: readonly number[], setSelected: React.Dispatch<React.SetStateAction<readonly number[]>>) => void
}

const DataTable: React.FC<IDataTableProps> = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] = useState<OrderByKey>(props.subjectFilter? "SUBJECT_ID" : "OPERATION_DATE");
    const [selected, setSelected] = useState<readonly number[]>([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [filterChanged, setFilterChanged] = useState(false);
    const [loadMore, setLoadMore] = useState(true)

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelecteds = props.data.map((n: { id: any; }) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: OrderByKey,
    ) => {
        const isAsc = order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        
        if (props.subjectFilter) {
            let filter: ISubjectsFilter = {
                ...props.subjectFilter,
                subjectFieldOrderBy: SubjectsFieldOrderBy[property.toString() as keyof typeof SubjectsFieldOrderBy],
                fieldsOrder: isAsc? FieldsOrder.ASC : FieldsOrder.DESC
            }
            if (props.onFilterChange)
                props.onFilterChange(filter)
        }

        if (props.operationFilter) {
            let filter: IOperationFilter = {
                ...props.operationFilter,
                operationsFieldOrderBy: OperationsFieldOrderBy[property.toString() as keyof typeof OperationsFieldOrderBy],
                fieldsOrder: isAsc? FieldsOrder.ASC : FieldsOrder.DESC
            }
            if (props.onFilterChange)
                props.onFilterChange(filter)
        }
    };

    React.useEffect(() => {
        setFilterChanged(true);
        setLoadMore(true)
    }, [props.operationFilter, props.subjectFilter, props.hasMore]);

    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleBulkDownload = (downloadOptions: ImageDownloadOption[]) => {
        downloadImageInfoFromSubjectBulk([...selected], downloadOptions).then(res => {}).catch(err => {
            enqueueSnackbar(err.message, { variant: 'error' });
        })
    }

    const handleLoadMore = () => {
        if(loadMore) {
            setLoadMore(false)
            let loadPage = currentPage
            if(filterChanged) {
                setCurrentPage(2)
                loadPage = 2
                setFilterChanged(false)
            }
            else {
                loadPage = currentPage + 1
                setCurrentPage(currentPage + 1)
            }
            props.onLoadMore(loadPage).then(() => {
                setLoadMore(true)
            })
        }
    }

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    return (
        <React.Fragment>            
            <EnhancedTableToolbar 
                selected={selected.length} 
                onDeleteClick={props.handleDelete !== undefined?
                        () => props.handleDelete?.(selected, setSelected) 
                        : undefined} 
                onDownloadClick={handleBulkDownload}
                toolbarTitle={props.title}
                DownloadTooltip={props.DownloadTooltip}
            />
            <TableContainer>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={handleLoadMore}
                    hasMore={props.hasMore}
                    loader={<LinearProgress key={Math.random().toString()} sx={{ margin: "1rem 0" }} />}
                >
                    <Table size="small" key={Math.random().toString()}>
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={props.data.length}
                            headCells={props.headCells}
                            inputProps={props.title.toLowerCase()}
                        />
                        <TableBody>
                            {props.data.map((row: any, index: any) => {
                                const isItemSelected = isSelected(Number(row.id));
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                onChange={(event: any) => handleClick(event, Number(row.id))}
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                        >
                                            {props.tableCells[0] !== "date"? 
                                                (row as any)[props.tableCells[0]]
                                                : new Date((row as any)[props.tableCells[0]]).toUTCString()
                                            }
                                        </TableCell>
                                        {props.tableCells.slice(1).map((id) => (
                                            <TableCell key={id} align="right">
                                                {id !== "date"? 
                                                    (id === "operationType") && (row as any)[id] === "CREATE_TEMPLATE"? "ENROLL" 
                                                        : (row as any)[id]
                                                    : new Date((row as any)[id]).toUTCString()
                                                }
                                            </TableCell>
                                        ))}
                                        <TableCell>
                                            {(props.onRowClick !== undefined) && ((row as any)["imageId"] !== 0)? 
                                                <IconButton size="small" onClick={() => props.onRowClick?.(row)}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                                : null
                                            }
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </InfiniteScroll>
            </TableContainer>
        </React.Fragment>
    )
}

export default DataTable
