import React, { useCallback, useEffect, useState } from 'react'
import { Box, Container, Grid, Paper, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack';
import DoneIcon from '@material-ui/icons/Done';
import SettingsView from '../../components/Settings/SettingsView';
import { getManagementInfo } from '../../Store/Management/actions';
import { clearDB } from '../../Store/Subjects/actions';
import { IManagementInfo } from '../../Store/Management/types';
import ActionConfirm from '../../components/Misc/ActionConfirm';



const Settings: React.FC = () => {

    const { enqueueSnackbar } = useSnackbar();
    const [managementInfo, setManagementInfo] = useState<IManagementInfo>();

    const fetchSettings = useCallback(async () => {
        try {
            const _managementInfo = await getManagementInfo();
            setManagementInfo(_managementInfo);
        } catch (err: any) {
            enqueueSnackbar(err.message, { variant: 'error' });
        }
    }, [enqueueSnackbar]);

    useEffect(() => {
        fetchSettings();
    }, [fetchSettings]);


    const clearManagementDB = () => {
        clearDB()
            .then(() => enqueueSnackbar("DB cleared", { variant: 'success' }))
            .catch(() => enqueueSnackbar("Unable to clear DB", { variant: 'error' }))
    }

    return (
        <Container maxWidth="lg">
            <Grid container spacing={3} >
                <Grid item xs={12} md={8}>
                    <SettingsView />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper elevation={10} sx={{ padding: "1rem", display: "flex", flexDirection: "column" }}>
                        <Typography variant="h6" sx={{ margin: "8px 0" }}>Management information</Typography>
                        <Box sx={{ display: "inline-flex", gap: 1 }}>
                            <Typography sx={{ fontWeight: 500 }}>
                                Allow settings override:
                            </Typography>
                            <Typography>
                                {managementInfo?.allowSettingsOverride ? "true" : "false"}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "inline-flex", gap: 1 }}>
                            <Typography sx={{ fontWeight: 500 }}>
                                Prefix mode:
                            </Typography>
                            <Typography>
                                {managementInfo?.prefixMode ? "true" : "false"}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "inline-flex", gap: 1 }}>
                            <Typography sx={{ fontWeight: 500 }}>
                                Management version:
                            </Typography>
                            <Typography>
                                {managementInfo?.version}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "inline-flex", gap: 1 }}>
                            <Typography sx={{ fontWeight: 500 }}>
                                Management revision:
                            </Typography>
                            <Typography>
                                {managementInfo?.revision.substr(0, 8)}
                            </Typography>
                        </Box>
                        <Box sx={{ paddingTop: 1, display: "flex", justifyContent: "flex-end" }}>
                            <ActionConfirm disabled={!managementInfo?.allowClearDb} color="error" variant="contained" text="Clear DB" confirmIcon={<DoneIcon />} onConfirm={clearManagementDB} />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Settings
