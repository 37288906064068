
export interface IDongle {
    hardwareId: String,
    dongleId: Number,
    applicationId: number,
    status: String,
    dongleVersion: String
}

export interface IStatus {
    status: LicensingStatusUpdate
}

export enum LicensingStatusUpdate {
    NONE = "NONE",
    FAILED = "FAILED",
    QUEUED = "QUEUED",
    IN_PROGRESS = "IN_PROGRESS",
    DONE = "DONE",
    UNKNOWN = "UNKNOWN"
}

export interface IInternetLicense {
    installationId: Number,
    internetStatus: String
}

export interface IUsage {
    used: Number,
    limit: Number,
    remaining: Number,
    operation: String
}
