import Axios from "axios";
import { getFullApiUrl } from "../../config/axios";

export const login = async (username: string, password: string) => {
    let data = "username=" + username + "&password=" + password;
    const response = await Axios.post<[{ authority: string }]>(getFullApiUrl() + "/login", data);
    return response;
}

export const logout = async () => {
    const response = await Axios.post(getFullApiUrl() + "/logout");
    return response.data;
}
