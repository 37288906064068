import { Box, IconButton, styled } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react'
import MenuItem, { IMenuItem } from './MenuItem';
import theme from '../../config/Theme';

const MenuContainer = styled(Box)(() => ({
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    zIndex: 10,
    backgroundColor: theme.palette.primary.main,
    minHeight: "450px"
}));

interface IMobileNavigationProps {
    currentPath: string,
    menuItems: IMenuItem[],
    onNavClose: () => void
}

const MobileNavigation: React.FC<IMobileNavigationProps> = (props) => {

    const isItemSelected = (menuIndex: number): boolean => {
        return props.menuItems[menuIndex].path === props.currentPath;
    }

    return (
        <MenuContainer>
            <IconButton onClick={() => props.onNavClose()} sx={{ color: "white", alignSelf: "flex-end", margin: "2rem" }}>
                <CloseIcon fontSize="large" />
            </ IconButton>
            <Box sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
            }}>
                {props.menuItems.map((x, i) => <MenuItem mobile onMenuItemClick={props.onNavClose} {...x} selected={isItemSelected(i)} key={Math.random().toString()} />)}
            </Box>
        </MenuContainer>
    )
}

export default MobileNavigation
