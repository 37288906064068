import { ThemeProvider } from '@material-ui/core/styles';
import { THEME } from './config';
import React from 'react';
import './App.css';
import Login from './containers/login/Login';
import { SnackbarProvider } from 'notistack';
import { Route, Router, Switch } from 'react-router';
import history from './config/history';
import PrivateRoute from './components/Route/PrivateRoute';
import Settings from './containers/Settings/Settings';
import Subjects from './containers/Subjects/Subjects';
import Licensing from './containers/Licensing/Licensing';
import configAxios from './config/axios';
import Operations from './containers/Operations/Operations';
import SnackbarCloseButton from './components/Misc/SnackbarCloseButton';

configAxios();

const App: React.FC = () => {

  return (
    <ThemeProvider theme={THEME}>
      <SnackbarProvider 
        maxSnack={3} anchorOrigin={{ horizontal: "center", vertical: "bottom" }} 
        action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
      >
        <Router history={history}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute
              exact
              path="/"
              component={Subjects} />
            <PrivateRoute
              exact
              path="/settings"
              component={Settings} />
            <PrivateRoute
              exact
              path="/subjects"
              component={Subjects} />
            <PrivateRoute
              exact
              path="/operations"
              component={Operations} />
            <PrivateRoute
              exact
              path="/licensing"
              component={Licensing} />
          </Switch>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
