import { Box, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import { IRectangle } from '../../Store/Operations/types';


interface IImageCanvasProps {
    base64Image: string | undefined,
    rectangle: IRectangle,
    showRect: boolean,
}

const ImageCanvas: React.FC<IImageCanvasProps> = (props) => {

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const divRef = useRef<HTMLElement>(null);

    const clearAndDraw = () => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");
            ctx?.clearRect(0, 0, canvas.width, canvas.height);
            draw();
        }
    }

    const draw = useCallback(() => {
        if (canvasRef.current && divRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext("2d");
            if (ctx && props.base64Image) {
                const image = new Image();
                image.src = "data:image/png;base64, " + props.base64Image;
                image.onload = () => {
                    if (divRef.current === null) {
                        return
                    }
                    // Setting image width/heigth scale
                    const aspectRatio = image.width / image.height;

                    canvas.width = divRef.current.offsetWidth
                    canvas.height = divRef.current.offsetHeight

                    const canvasAspectRation = canvas.width / canvas.height

                    let canvasCurrentWidth = canvas.width;
                    let canvasCurrentHeight = canvas.height;
                    
                    let rectangleLineWidth

                    if (aspectRatio > canvasAspectRation) { //image is in landscape
                        canvasCurrentHeight = canvasCurrentWidth / aspectRatio;
                        canvasCurrentWidth = canvasCurrentHeight * aspectRatio;
                        rectangleLineWidth = canvasCurrentHeight / 85
                    }
                    else { // image is in portrait
                        canvasCurrentWidth = canvasCurrentHeight * aspectRatio;
                        canvasCurrentHeight = canvasCurrentWidth / aspectRatio;
                        rectangleLineWidth = canvasCurrentWidth / 85
                    }

                    const horizontalCenter = (canvas.width - canvasCurrentWidth) / 2;
                    const verticalCenter = (canvas.height - canvasCurrentHeight) / 2;

                    ctx.clearRect(0, 0, canvas.width, canvas.height)

                    ctx.drawImage(image, horizontalCenter, verticalCenter, canvasCurrentWidth, canvasCurrentHeight);
                    if (props.showRect) {
                        ctx.strokeStyle = "green"
                        ctx.beginPath();
                        ctx.lineWidth = rectangleLineWidth
                        const rect = props.rectangle;
                        const widthProportion = image.width / canvasCurrentWidth;
                        const heightProportion = image.height / canvasCurrentHeight;
                        ctx.rect(
                            (rect.x / widthProportion) + horizontalCenter,
                            (rect.y / heightProportion) + verticalCenter,
                            rect.width / widthProportion,
                            rect.height / heightProportion);
                        ctx.stroke()
                    }
                }
            }
        }
    }, [props]);

    useEffect(() => {
        clearAndDraw();
    });

    useLayoutEffect(() => {
        draw();
        window.addEventListener('resize', draw);
        return () =>
            window.removeEventListener('resize', draw);
    }, [draw])

    return (
        <Box ref={divRef} sx={{ display: "flex", flexGrow: 1, justifyContent: "center", alignItems: "center", height: "35vh" }}>
            {props.base64Image ?
                <canvas ref={canvasRef} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                :
                <Typography>No image</Typography>
            }
        </Box>
    )
}

export default ImageCanvas
