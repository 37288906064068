import React, { Ref, useRef, useState } from 'react'
import { Button, ButtonGroup, Checkbox, FormControlLabel, FormGroup, Grow, Paper, Popper, Theme, useMediaQuery } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useSnackbar } from 'notistack';


export type ImageDownloadOption = "IMAGE" | "TOKEN_IMAGE" | "TEMPLATE";

interface IMenuItem {
    option: ImageDownloadOption
    name: string,
    checked: boolean
}

const MenuOptions: IMenuItem[] = [
    {
        name: 'Image',
        option: "IMAGE",
        checked: true
    },
    {
        name: 'Token image',
        option: "TOKEN_IMAGE",
        checked: true
    },
    {
        name: 'Template',
        option: "TEMPLATE",
        checked: true
    }
]

interface IImageInfoDownloadProps {
    onDownloadClick: (downloadOptions: ImageDownloadOption[]) => void
}

const ImageInfoDownload: React.FC<IImageInfoDownloadProps> = React.forwardRef(({ onDownloadClick, ...rest }, ref: Ref<HTMLDivElement>) => {

    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const [downloadSelection, setDownloadSelection] = useState<IMenuItem[]>(MenuOptions);
    const breakpointMatch = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const { enqueueSnackbar } = useSnackbar();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleCheckBoxClick = (optionClick: ImageDownloadOption) => {
        let options = [...downloadSelection];
        let optionIndex = options.findIndex(x => x.option === optionClick);
        let option = options[optionIndex];
        option.checked = !option.checked;
        setDownloadSelection(options);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }
        setOpen(false);
    };

    const handleDownloadClick = () => {
        let selectedOptions = downloadSelection.filter(x => x.checked).map(x => x.option);
        if (selectedOptions.length === 0) {
            enqueueSnackbar("At least one option for download must be selected", { variant: "warning" })
            return;
        }
        onDownloadClick(selectedOptions);
    }

    const getDownloadText = () => {
        if (breakpointMatch)
            return <GetAppIcon />;
        return "Download"
    }

    return (
        <div ref={ref} {...rest}>
            <ButtonGroup variant="contained" color="secondary" ref={anchorRef} aria-label="split button" sx={{ margin: "0 0.5rem" }}>
                <Button onClick={handleDownloadClick}>{getDownloadText()}</Button>
                <Button
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                > <ArrowDropDownIcon /></Button>
            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{ zIndex: 1 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom'
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <FormGroup id="split-button-menu" sx={{ padding: 1 }}>
                                    {downloadSelection.map((option) => (
                                        <FormControlLabel
                                            key={option.name}
                                            control={<Checkbox size="small" checked={option.checked} />}
                                            label={option.name}
                                            onClick={() => handleCheckBoxClick(option.option)}
                                        />
                                    ))}
                                </FormGroup>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
});

export default ImageInfoDownload
