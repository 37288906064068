import { Box, Button, styled, Typography } from '@material-ui/core'
import React from 'react'
import { NavLink } from 'react-router-dom'

const NavLinkStyled = styled(NavLink)({
    textDecoration: "none"
});


export interface IMenuItem {
    name: string,
    path?: string,
    type?: "none" | "contained",
    onMenuItemClick?: () => void
}

export interface IMenuItemProps extends IMenuItem {
    selected?: boolean
    mobile?: boolean
}

const MenuItem: React.FC<IMenuItemProps> = (props) => {

    const handleMenuItemClick = () => {
        if (props.onMenuItemClick)
            props.onMenuItemClick();
    }

    const containedStyledButton = () => {
        if (props.mobile)
            return unstyledButton();
        return (
            <Button variant="contained" size="large">
                {props.name}
            </Button>
        );
    }

    const unstyledButton = () => {
        return (
            <Typography sx={{
                color: "white",
                fontSize: "1.1rem",
                fontWeight: "500",
                textAlign: "center",
                textDecoration: (props.selected && !props.mobile) ? "underline" : "none",
                textDecorationColor: "#9D9D9D",
                textDecorationThickness: "5px",
                textUnderlinePosition: "under"
            }}>
                {props.name}
            </Typography>
        );
    }

    const renderContent = () => {
        return (
            <Box onClick={handleMenuItemClick} sx={{
                padding: "1.2rem",
                cursor: "pointer"
            }}>
                {props.type === 'contained' ? containedStyledButton() : unstyledButton()}
            </Box>
        );
    };

    return (
        <React.Fragment>
            {props.path ? (
                <NavLinkStyled to={props.path || ''} onClick={handleMenuItemClick} sx={{
                    backgroundColor: (props.selected && props.mobile) ? "#2f655a" : ""
                }}>
                    {renderContent()}
                </NavLinkStyled>
            ) : (
                renderContent()
            )}
        </React.Fragment>
    )
}

export default MenuItem
