import { Box, Button, Input, Paper, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { generateDongleDump } from "../../Store/Licensing/actions";
import { IStatus, LicensingStatusUpdate } from "../../Store/Licensing/types";
import { useSnackbar } from "notistack";
import { HttpError } from "../../config/types";

interface IDongleUpdateProbs {
    updateStatus: IStatus
    handleDongleUpdate: any
}

const DongleUpdate: React.FC<IDongleUpdateProbs> = ({updateStatus, handleDongleUpdate}) => {

    const { enqueueSnackbar } = useSnackbar();

    const [updateTitle, setUpdateTitle] = useState<string>("Update")
    const [generateTitle, setGenerateTitle] = useState<string>("Generate");
    const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(false)

    useEffect(() => {
        const setInitButtons = () => {
            if (updateStatus.status === LicensingStatusUpdate.IN_PROGRESS || updateStatus.status === LicensingStatusUpdate.QUEUED) {
                setUpdateTitle("Updating...")
                setButtonsDisabled(true);
            }
            else {
                setUpdateTitle("Update")
                setButtonsDisabled(false);
            }
        }
        setInitButtons();
    }, [updateStatus])

    const handleGenerateClick = async () => {
        setGenerateTitle("Generating...")
        setButtonsDisabled(true)
        const dump = await generateDongleDump().catch((e: HttpError) => {
            if (e)
                enqueueSnackbar(e.status + ": " + e.message, { variant: 'error' });
            else 
                enqueueSnackbar("Cannot generate dongle dump, undefined error", { variant: 'error' });
        });
        setGenerateTitle("Generate")
        setButtonsDisabled(false)
        if (!dump)
            return;
        let atag = document.createElement("a");
        let file = new Blob([dump], { type: "text/plain" });
        atag.href = URL.createObjectURL(file);
        atag.download = "dump.dat";
        atag.click();
    };

    return (
        <React.Fragment>
            <Paper elevation={10} sx={{ padding: "1rem", display: "flex", flexDirection: "column", marginTop: "1.5rem" }}>
                <Typography variant="h6" sx={{ margin: "8px, 0" }}>Dongle update</Typography>
                <Box
                    component="form"
                    noValidate
                    onSubmit={handleDongleUpdate}
                    sx={{ display: "flex", gap: 1, justifyContent: "center" }}
                    style={{paddingBottom: "1rem"}}
                >
                    <Input
                        id="dongle-update"
                        name="dongle-update"
                        type="file"
                    />
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={buttonsDisabled}
                    >
                        {updateTitle}
                    </Button>
                </Box>
                <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
                    <Typography sx={{ textAlign: "center", paddingTop: "0.5rem" }}>
                        Generate dongle dump
                    </Typography>
                    <Button variant="contained" onClick={handleGenerateClick} disabled={buttonsDisabled}>
                        {generateTitle}
                    </Button>
                </Box>
            </Paper>
        </React.Fragment>
    )
}

export default DongleUpdate