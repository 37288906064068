import Axios, { AxiosRequestConfig } from "axios";
import { getFullApiUrl } from "../../config/axios";
import { IManagementInfo, ISetting } from "./types";

export const getManagementInfo = async () => {
    const response = await Axios.get<IManagementInfo>(getFullApiUrl() + "/management/info");
    return response.data;
}

export const getSettings = async () => {
    const response = await Axios.get<Object>(getFullApiUrl() + "/management/settings");
    let settingsArray: ISetting[] = [];
    Object.entries(response.data).forEach(([key, value]) => {
        settingsArray.push({ key, value })
    });
    return settingsArray;
}

export const getSetting = async (key: String) => {
    let config: AxiosRequestConfig = {
        params: {
            key: key
        }
    }
    const response = await Axios.get<Object>(getFullApiUrl() + "/management/setting", config);
    return response.data;
}

export const setSetting = async (key: String, value: String | null) => {
    let config: AxiosRequestConfig = {
        params: {
            key: key,
            value: value
        }
    }
    const response = await Axios.post(getFullApiUrl() + "/management/settings", null, config);
    return response.data;
}

export const resetSettings = async () => {
    const response = await Axios.post(getFullApiUrl() + "/management/settings/reset");
    return response.data;
}

export const toggleDebug = async (toggle: boolean) => {
    let data: AxiosRequestConfig = {
        params: {
            toggle: toggle,
        }
    }
    const response = await Axios.post(getFullApiUrl() + "/management/toggle-debug", data);
    return response.data;
}
