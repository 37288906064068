
import React, { useState } from 'react'
import { Box, Button, CircularProgress, Paper, TextField } from '@material-ui/core'
import { styled } from '@material-ui/system'
import Authentication from '../../Store/Authentication/AuthenticationStore';
import { useSnackbar } from 'notistack';
import { RouteComponentProps } from 'react-router';
import Logo from '../../components/Logo/Logo';

const LoginContainer = styled(Box)(({ theme }) => ({
    top: "50%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    margin: "auto",
    [theme.breakpoints.up("sm")]: {
        width: "550px"
    },
    [theme.breakpoints.down("sm")]: {
        width: "350px"
    }
}));

const LoginInnerContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1.5rem",
    [theme.breakpoints.down("sm")]: {
        padding: "1.5rem"
    }
}));

const Login: React.FC<RouteComponentProps> = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const handleError = (error: Error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        const data = new FormData(event.currentTarget);
        Authentication.Authenticate(data.get("username") as string, data.get("password") as string)
            .then(() => props.history.push("/"))
            .catch(error => {
                setLoading(false);
                handleError(error);
            });
    }

    return (
        <LoginContainer>
            <Paper elevation={10}>
                <LoginInnerContainer>
                    <Logo />
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ marginTop: 1, textAlign: "center" }}>
                        <TextField
                            sx={{margin: "5px"}}
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoFocus />
                        <TextField
                            sx={{margin: "5px"}}
                            required
                            fullWidth
                            id="passwordd"
                            label="Password"
                            name="password"
                            type="password"
                        />
                        {loading ?
                            (<CircularProgress sx={{ marginTop: 3, marginBottom: 3 }} />)
                            :
                            (<Button type="submit" size="large" fullWidth variant="contained" sx={{ marginTop: 3, marginBottom: 3 }}>
                                Log In
                            </Button>)}
                    </Box>
                </LoginInnerContainer>
            </Paper>
        </LoginContainer >
    )
}

export default Login
