import { Box, Button, Container, Paper, Tooltip, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react'
import { useCallback } from 'react';
import AddSubjectDialog from '../../components/AddSubject/AddSubjectDialog';
import SubjectFilter from '../../components/Filter/SubjectFilter';
import DataTable, { HeadCell } from '../../components/Misc/DataTable/DataTable';
import ImageInfoDownload, { ImageDownloadOption } from '../../components/ImageView/ImageInfoDownload';
import ImageViewDialog from '../../components/ImageView/ImageViewDialog';
import { IImageInfo } from '../../Store/Image/types';
import { countSubjects, deleteSubject, deleteSubjectsBulk, getSubjects } from '../../Store/Subjects/actions'
import { FieldsOrder, ISubject, ISubjectsFilter, SubjectsFieldOrderBy } from '../../Store/Subjects/types';
import { downloadImageInfoFromSubject, getImage } from '../../Store/Image/actions';

const headCells: HeadCell[] = [
    {
        id: 'SUBJECT_ID',
        align: 'left',
        disablePadding: true,
        label: 'Subject ID',
    },
    {
        id: 'SUBJECT_DATE',
        align: 'right',
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'SUBJECT_STATUS',
        align: 'right',
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'SUBJECT_LIVENESS_SCORE',
        align: 'right',
        disablePadding: false,
        label: 'Liveness score',
    },
    {
        id: 'SUBJECT_QUALITY',
        align: 'right',
        disablePadding: false,
        label: 'Quality',
    },
];

export interface IIds {
    subjectId: number | undefined,
    operationId: number | undefined,
    imageId: number | undefined,
} 

interface IDownloadImageTooltip {
    onDownloadClick: ((downloadOptions: ImageDownloadOption[]) => void)
}

const DownloadSubjectTooltip: React.FC<IDownloadImageTooltip> = (props) => {
    return(
        <Tooltip title="">
            <ImageInfoDownload onDownloadClick={props.onDownloadClick} />
        </Tooltip>
    );
}

const Subjects: React.FC = () => {

    const { enqueueSnackbar } = useSnackbar();
    const [subjectsFilter, setSubjectsFilter] = useState<ISubjectsFilter>({
        subjectId: "",
        insertDateLte: "",
        insertDateGte: "",
        qualityLte: null,
        qualityGte: null,
        livenessScoreLte: null,
        livenessScoreGte: null,
        subjectFieldOrderBy: SubjectsFieldOrderBy.SUBJECT_ID,
        fieldsOrder: FieldsOrder.ASC
    });
    const [subjectCount, setSubjectCount] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [subjects, setSubjects] = useState<ISubject[]>([]);
    const [showImage, setShowImage] = useState(false);
    const [addSubject, setAddSubject] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState<ISubject>();
    const [selectedImage, setSelectedImage] = useState(0);

    const refreshList = useCallback(async (filter: ISubjectsFilter | undefined) => {
        try {
            const data = await getSubjects(filter, 0, 20);
            const count = await countSubjects(filter);
            setSubjects(data);
            setSubjectCount(count);
            setHasMore(true);
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    }, [enqueueSnackbar]);

    useEffect(() => {
        const fetchCount = async () => {
            try {
                const count = await countSubjects(undefined);
                setSubjectCount(count);
            } catch (error) {
            }
        };
        fetchCount();
    }, [])

    const handleSubjectDelete = () => {
        if (selectedSubject) {
            deleteSubject(selectedSubject.id).then(res => {
                enqueueSnackbar("Subject deleted successfully", { variant: 'success' });
                setSelectedSubject(undefined)
                setShowImage(false);
                refreshList(subjectsFilter as ISubjectsFilter);
            }).catch(err => {
                enqueueSnackbar(err.message, { variant: 'error' });
            });
        }
    }

    const handleFilterChange = (filter: ISubjectsFilter) => {
        setSubjectsFilter(filter)
        refreshList(filter);
    }

    const loadSubjects = async (page: number): Promise<any> => {
        if (!hasMore) return new Promise<any>(() => false);
        const size = 20;
        if (subjects.length >= 1000) {
            setHasMore(false);
            enqueueSnackbar("Maximum subject display has been reached (1000)", { variant: 'info' });
            return;
        }

        return (getSubjects(subjectsFilter, page, size).then(res => {
            if (res.length > 0) {
                setSubjects(prev => ([...prev, ...res]));
            }
            if (res.length === 0 || res.length !== size) {
                setHasMore(false);
            }
            }).catch(err => {
                enqueueSnackbar(err.message, { variant: 'error' });
                setHasMore(false);
            })
        )
    }

    const handleDownload = (downloadOptions: ImageDownloadOption[]) => {
        if (selectedSubject)
            downloadImageInfoFromSubject(selectedSubject, downloadOptions).catch(err => {
                enqueueSnackbar("Unable to download", { variant: 'error' });
            });
    }

    const handleSubjectClick = (subject: ISubject) => {
        setShowImage(true);
        setSelectedSubject(subject);
        setSelectedImage(subject.imageId)
    }

    const handleAddSubjectClose = () => {
        setAddSubject(false);
        setSubjectsFilter(subjectsFilter);
        refreshList(subjectsFilter as ISubjectsFilter);
    }

    const handleBulkDelete = (selected: readonly number[], setSelected: React.Dispatch<React.SetStateAction<readonly number[]>>) => {
        deleteSubjectsBulk([...selected]).then(res => {
            enqueueSnackbar("Selected subjects successfully deleted", { variant: 'info' });
            setSelected([]);
            refreshList(subjectsFilter);
        }).catch(err => {
            enqueueSnackbar(err.message, { variant: 'error' });
        })
    }

    const handleGetImageInfo = async () => {
        if (selectedSubject === undefined) return
        let imageDb = await getImage(selectedImage);
        let imageInfo: IImageInfo = {
            image: imageDb.image,
            id: selectedSubject.id,
            template: imageDb.template,
            tokenImage: imageDb.tokenImage,
            operationType: selectedSubject.operationType,
            status: selectedSubject.status,
            livenessMode: selectedSubject.livenessMode,
            date: selectedSubject.date,
            uuid: selectedSubject.uuid,
            imageId: imageDb.id,
            yaw: selectedSubject.yaw,
            roll: selectedSubject.roll,
            pitch: selectedSubject.pitch,
            livenessTargetYaw: selectedSubject.livenessTargetYaw,
            livenessScore: selectedSubject.livenessScore,
            quality: selectedSubject.quality,
            boundingRect: selectedSubject.boundingRect,
            age: selectedSubject.age
        }
        return imageInfo
    }

    return (
        <React.Fragment>
            <SubjectFilter onFilterApply={handleFilterChange} />
            <Container maxWidth="lg" sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "1rem" }}>
                    <Typography>Showing {subjects.length} out of {subjectCount} subjects</Typography>
                    <Button variant="contained" onClick={() => setAddSubject(true)}>Create Subject</Button>
                </Box>
                <Paper sx={{ padding: "1rem", marginBottom: "1rem" }} elevation={10}>
                    <DataTable
                        data={subjects}
                        subjectFilter={subjectsFilter}
                        onFilterChange={handleFilterChange}
                        hasMore={hasMore}
                        onRowClick={handleSubjectClick}
                        onLoadMore={loadSubjects}
                        headCells={headCells}
                        tableCells={['subjectId', 'date', 'status', 'livenessScore', 'quality']}
                        title={'Subjects'}
                        dateName={'date'}
                        DownloadTooltip={DownloadSubjectTooltip}
                        handleDelete={handleBulkDelete}
                    />
                </Paper>
            </Container>
            <AddSubjectDialog
                open={addSubject}
                onClose={handleAddSubjectClose}
            />
            <ImageViewDialog
                getInfo={handleGetImageInfo}
                open={showImage}
                onClose={() => setShowImage(false)}
                onImageDelete={handleSubjectDelete}
                onImageDownload={handleDownload}
                isDeletable={true}
            />
        </React.Fragment>
    )
}

export default Subjects
