import { Box, Container, Paper, Typography } from "@material-ui/core"
import { useSnackbar } from "notistack"
import React, { useCallback, useEffect, useState } from "react"
import OperationFilter from "../../components/Filter/OperationFilter"
import { ImageDownloadOption } from "../../components/ImageView/ImageInfoDownload"
import ImageViewDialog from "../../components/ImageView/ImageViewDialog"
import DataTable, { HeadCell } from "../../components/Misc/DataTable/DataTable"
import { downloadImageInfoFromOperation, getImage } from "../../Store/Image/actions"
import { IImageInfo } from "../../Store/Image/types"
import { countOperations, deleteBulkOperation, deleteOperation, getOperations } from "../../Store/Operations/actions"
import { IOperation, IOperationFilter, OperationsFieldOrderBy, Status } from "../../Store/Operations/types"
import { FieldsOrder } from "../../Store/Subjects/types"


const headCells: HeadCell[] = [
    {
        id: 'OPERATION_DATE',
        align: 'left',
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'OPERATION_STATUS',
        align: 'right',
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'OPERATION_LIVENESS_MODE',
        align: 'right',
        disablePadding: false,
        label: 'Liveness mode',
    },
    {
        id: 'OPERATION_TYPE',
        align: 'right',
        disablePadding: false,
        label: 'Operation type',
    },
    {
        id: 'UUID',
        align: 'right',
        disablePadding: false,
        label: 'UUID',
    },
];

const Operations: React.FC = () => {

    const { enqueueSnackbar } = useSnackbar();
    const [hasMore, setHasMore] = useState(true);

    const [operationsFilter, setOperationsFilter] = useState<IOperationFilter>({
        dateLte: "",
        dateGte: "",
        statuses: [],
        operationTypes: [],
        livenessModes: [],
        uuid: "",
        operationsFieldOrderBy: OperationsFieldOrderBy.OPERATION_DATE,
        fieldsOrder: FieldsOrder.ASC
    });
    const [operations, setOperations] = useState<IOperation[]>([]);
    const [operationsCount, setOperationsCount] = useState(0);
    const [showImage, setShowImage] = useState(false)
    const [selectedOperation, setSelectedOperation] = useState<IOperation>();
    const [selectedImage, setSelectedImage] = useState(0);
    const [isDeletable, setIsDeletable] = useState(false);

    const refreshList = useCallback(async (filter: IOperationFilter | undefined) => {
        try {
            const data = await getOperations(filter, 0, 20);
            const count = await countOperations(filter);
            setOperations(data);
            setOperationsCount(count);
            setHasMore(true);
            
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    }, [enqueueSnackbar]);

    useEffect(() => {
        const fetchCount = async () => {
            try {
                const count = await countOperations(undefined);
                setOperationsCount(count);
            } catch (error) {
            }
        };
        fetchCount();
    }, [])

    const handleFilterChange = (filter: IOperationFilter) => {
        setOperationsFilter(filter)
        refreshList(filter);
    }

    const loadOperations = async (page: number): Promise<any> => {
        if (!hasMore) return new Promise(() => false);
        const size = 20;
        if (operations.length >= 1000) {
            setHasMore(false);
            enqueueSnackbar("Maximum operations display has been reached (1000)", { variant: 'info' });
            return;
        }
        
        return (getOperations(operationsFilter, page, size).then(res => {
            if (res.length > 0) {
                setOperations(prev => ([...prev, ...res]));
            }

            if (res.length === 0 || res.length !== size) {
                setHasMore(false);
            }
            }).catch(err => {
                enqueueSnackbar(err.message, { variant: 'error' });
                setHasMore(false);
            })
        )
    }

    const handleBulkDelete = (selected: readonly number[], setSelected: React.Dispatch<React.SetStateAction<readonly number[]>>) => {
        let operationsList: IOperation[] = []
        selected.forEach((val) => {
            operations.forEach((operation) => {
                if(val === operation.id ) {
                    operationsList.push(operation)
                }
            })
        })
        if (operationsList.length === 0) {
            enqueueSnackbar("No selected operations were deleted", { variant: 'warning' });
            return;
        }
        deleteBulkOperation(operationsList).then(res => {
            enqueueSnackbar(res + " Selected operations successfully deleted", { variant: 'info' });
            setSelected([]);
            refreshList(operationsFilter);
        }).catch(err => {
            enqueueSnackbar("No selected operations were deleted", { variant: 'warning' });
        })
    }

    const handleOperationDelete = () => {
        if (selectedOperation) {
            deleteOperation(selectedOperation).then(res => {
                enqueueSnackbar("Operation deleted successfully", { variant: 'success' });
                setSelectedOperation(undefined)
                setShowImage(false);
                refreshList(operationsFilter as IOperationFilter);
            }).catch(err => {
                enqueueSnackbar(err.message, { variant: 'error' });
            });
        }
    }

    const handleDownload = (downloadOptions: ImageDownloadOption[]) => {
        if (selectedOperation)
            downloadImageInfoFromOperation(selectedOperation, downloadOptions).catch(err => {
                enqueueSnackbar("Unable to download", { variant: 'error' });
            });
    }

    const handleOperationClick = (operation: IOperation) => {
        setShowImage(true);
        if (operation.operationType.toString() === "CREATE_TEMPLATE" && operation.status === Status.SUCCESS) {
            setIsDeletable(false)
        }
        else {
            setIsDeletable(true)
        }
        setSelectedOperation(operation);
        setSelectedImage(operation.imageId)
    }

    const handleGetImageInfo = async () => {
        if (selectedOperation === undefined) return
        let imageDb = await getImage(selectedImage);
        let imageInfo: IImageInfo = {
            image: imageDb.image,
            id: selectedOperation.id,
            template: imageDb.template,
            tokenImage: imageDb.tokenImage,
            operationType: selectedOperation.operationType,
            status: selectedOperation.status,
            livenessMode: selectedOperation.livenessMode,
            date: selectedOperation.date,
            uuid: selectedOperation.uuid,
            imageId: imageDb.id,
            yaw: selectedOperation.yaw,
            roll: selectedOperation.roll,
            pitch: selectedOperation.pitch,
            livenessTargetYaw: selectedOperation.livenessTargetYaw,
            livenessScore: selectedOperation.livenessScore,
            quality: selectedOperation.quality,
            boundingRect: selectedOperation.boundingRect,
            age: selectedOperation.age
        }
        return imageInfo
    }

    return (
        <React.Fragment>
            <OperationFilter onFilterApply={handleFilterChange} />
            <Container maxWidth="lg" sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingBottom: "1rem" }}>
                    <Typography>Showing {operations.length} out of {operationsCount} operations</Typography>
                </Box>
                <Paper sx={{ padding: "1rem", marginBottom: "1rem" }} elevation={10}>
                    <DataTable
                        data={operations}
                        operationFilter={operationsFilter}
                        onFilterChange={handleFilterChange}
                        hasMore={hasMore}
                        onRowClick={handleOperationClick}
                        onLoadMore={loadOperations}
                        tableCells={['date', 'status', 'livenessMode', 'operationType', 'uuid']}
                        headCells={headCells}
                        title={'Operations'}
                        dateName={'date'}
                        handleDelete={handleBulkDelete}
                    />
                </Paper>
            </Container>
            <ImageViewDialog
                getInfo={handleGetImageInfo}
                open={showImage}
                onClose={() => setShowImage(false)}
                onImageDelete={handleOperationDelete}
                onImageDownload={handleDownload}
                isDeletable={isDeletable}
            />
        </React.Fragment>
    )
}

export default Operations
