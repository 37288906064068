import React from "react";
import { Route, Redirect } from "react-router-dom";
import Layout from "../../containers/layout/Layout";
import Authentication from "../../Store/Authentication/AuthenticationStore";

interface IProps {
    exact?: boolean;
    path: string;
    component: React.FC<any>;
}
const PrivateRoute = ({ component: Component, ...otherProps }: IProps) => (
    <Route
        {...otherProps}
        render={props =>
            Authentication.isAuthenticated() ? (
                <Layout>
                    <Component {...props} />
                </Layout>
            ) : (
                <Redirect
                    to={{ pathname: "/login", state: { referrer: props.location } }}
                />
            )
        }
    />
);

export default PrivateRoute;
