import Axios, { AxiosRequestConfig } from "axios";
import { getFullApiUrl } from "../../config/axios";
import qs from "querystring"
import { ISubject, ISubjectsFilter } from "./types";

const convertToInLineArray = (params: any): string => {
    const filterNonNull = (obj: Object) => {
        return Object.fromEntries(Object.entries(obj).filter(([k, v]) => v));
    }
    return qs.stringify(filterNonNull(params))
};

export const getSubjects = async (filter: ISubjectsFilter | undefined, page?: number, size?: number) => {

    let config: AxiosRequestConfig = {
        params: {
            page: page,
            size: size,
            ...filter
        },
        paramsSerializer: convertToInLineArray
    }
    const response = await Axios.get<ISubject[]>(getFullApiUrl() + "/subjects", config);
    return response.data;
}

export const getSubjectInfo = async (id: number) => {
    const response = await Axios.get<ISubject>(getFullApiUrl() + "/subjects/id/" + id);
    return response.data;
}

export const countSubjects = async (filter: ISubjectsFilter | undefined) => {
    let config: AxiosRequestConfig = {
        params: { ...filter },
        paramsSerializer: convertToInLineArray
    }
    const response = await Axios.get<number>(getFullApiUrl() + "/subjects/count", config);
    return response.data;
}

export const deleteSubject = async (subjectId: number) => {
    let config: AxiosRequestConfig = {
        params: {
            id: subjectId,
        },
        paramsSerializer: convertToInLineArray
    }
    const response = await Axios.delete(getFullApiUrl() + "/subjects/id/", config);
    return response.data;
}

export const clearDB = async () => {
    const response = await Axios.delete(getFullApiUrl() + "/subjects/clear-db");
    return response;
}

export const createSubject = async (subjectId: string, image: string, nTemplate: string, fvTemplate: string) => {
    let data = {
        subjectId: subjectId,
        image: image,
        template: nTemplate,
        fvTemplate: fvTemplate
    }
    const response = await Axios.post<ISubject>(getFullApiUrl() + "/subjects/create", data);
    return response.data;
}

export const deleteSubjectsBulk = async (subjectIds: number[]) => {
    let config: AxiosRequestConfig = {
        params: {
            subjectIds: subjectIds,
        },
        paramsSerializer: convertToInLineArray
    }
    const response = await Axios.delete(getFullApiUrl() + "/subjects", config);
    return response.data;
}
