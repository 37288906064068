import Axios, { AxiosRequestConfig } from "axios";
import { getFullApiUrl } from "../../config/axios";
import { IDongle, IInternetLicense, IStatus, IUsage } from "./types";

export const getLicensingUsage = async () => {
    const response = await Axios.get<IUsage[]>(getFullApiUrl() + "/licensing/usage");
    return response.data;
}

export const getDongles = async () => {
    const response = await Axios.get<IDongle[]>(getFullApiUrl() + "/licensing/dongles");
    return response.data;
}

export const getInternetLicenses = async () => {
    const response = await Axios.get<IInternetLicense[]>(getFullApiUrl() + "/licensing/internet");
    return response.data
}

export const getToken = async () => {
    const response = await Axios.get<string>(getFullApiUrl() + "/licensing/token");
    return response.data;
}

export const getUpdateStatus = async () => {
    const response = await Axios.get<IStatus>(getFullApiUrl() + "/licensing/update-status");
    return response.data;
}

export const updateDongle = async (update: String) => {
    let config: AxiosRequestConfig = {
        headers: {
            "Content-Type": "text/plain"
        }
    }
    const response = await Axios.post<IStatus>(getFullApiUrl() + "/licensing/update", update, config);
    return response.data;
}

export const generateDongleDump = async () => {
    const response = await Axios.get<string>(getFullApiUrl() + "/licensing/generate-dongle-dump");
    return response.data;
}
