import React from 'react'
import { Grid, TextField, Typography } from '@material-ui/core'

interface INumberRangeProps {
    label: string,
    from?: number,
    to?: number,
    onFromNumberChange: (number: number) => void,
    onToNumberChange: (number: number) => void
}

const NumberRange: React.FC<INumberRangeProps> = (props) => {

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let number = parseInt(event.target.value);
        switch (event.target.id) {
            case "number-from":
                props.onFromNumberChange(number);
                break;
            case "number-to":
                props.onToNumberChange(number);
                break;
        }
    }

    const getNumberValue = (number: number | undefined): number | string => {
        if (number && !isNaN(number)) {
            return number
        } else {
            return "";
        }
    }
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography sx={{ marginBottom: "-5px" }}>
                    {props.label}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    size="small"
                    type="number"
                    label="From"
                    value={getNumberValue(props.from)}
                    onChange={handleInputChange}
                    id="number-from"
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    size="small"
                    type="number"
                    label="To"
                    value={getNumberValue(props.to)}
                    id="number-to"
                    onChange={handleInputChange}
                />
            </Grid>
        </Grid>
    )
}

export default NumberRange
