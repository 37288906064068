import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'
import ActionConfirm from '../Misc/ActionConfirm'
import ImageView from './ImageView'
import DoneIcon from '@material-ui/icons/Done';
import { IImageInfo } from '../../Store/Image/types'
import ImageInfoDownload, { ImageDownloadOption } from './ImageInfoDownload';



interface IImageViewDialogProps {
    getInfo: () => Promise<IImageInfo | undefined> | undefined,
    open: boolean,
    onClose: () => void,
    onImageDelete: () => void,
    onImageDownload: (downloadOptions: ImageDownloadOption[]) => void,
    isDeletable: boolean,
}

const ImageViewDialog: React.FC<IImageViewDialogProps> = (props) => {
    return (
        <Dialog
            maxWidth="md"
            scroll="body"
            fullWidth
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>Subject information</DialogTitle>
            <DialogContent>
                <ImageView 
                    getInfo={props.getInfo}
                />
            </DialogContent>
            <DialogActions>
                {props.isDeletable? 
                <ActionConfirm 
                    onConfirm={props.onImageDelete} 
                    color="error" 
                    variant="contained" 
                    text="Delete" 
                    confirmIcon={<DoneIcon />} />
                : null}
                <ImageInfoDownload onDownloadClick={props.onImageDownload} />
                <Box style={{ flex: '1 0 0' }} />
                <Button autoFocus onClick={props.onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ImageViewDialog
