import React, { useState } from 'react'
import { Box, Button, ClickAwayListener, IconButton, SvgIconProps } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface IActionConfirmProps {
    color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    variant: 'text' | 'outlined' | 'contained',
    text: String,
    confirmIcon: React.ReactElement<SvgIconProps>,
    buttonIcon?: React.ReactElement<SvgIconProps>,
    disabled?: boolean,
    compact?: boolean,
    onConfirm: () => void
}

const ActionConfirm = React.forwardRef<HTMLDivElement, IActionConfirmProps>(({ onConfirm, text, variant, color, confirmIcon, buttonIcon, disabled, compact, ...rest }, ref) => {

    const [confirmationRequired, setConfirmationRequired] = useState(false);

    const handleDelete = () => {
        setConfirmationRequired(false);
        onConfirm();
    }


    const getButton = () => {
        if (compact) {
            return (
                <IconButton color={color} size="small" onClick={() => setConfirmationRequired(true)}>
                    {buttonIcon ? buttonIcon : text}
                </IconButton>
            );
        } else {
            return (
                <Button disabled={disabled} variant={variant} color={color} onClick={() => setConfirmationRequired(true)}>
                    {text}
                </Button>
            );
        }

    }

    return (
        <div ref={ref} {...rest}>
            {confirmationRequired ?
                <ClickAwayListener onClickAway={() => setConfirmationRequired(false)}>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <IconButton color={color} size={compact ? "small" : "medium"} onClick={handleDelete}>
                            {confirmIcon}
                        </IconButton>
                        <IconButton color="primary" size={compact ? "small" : "medium"} onClick={() => setConfirmationRequired(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </ClickAwayListener>
                :
                getButton()
            }
        </div>
    )
});

export default ActionConfirm
