import { Theme, useMediaQuery } from '@material-ui/core'
import React from 'react'
import {IInternetLicense } from '../../Store/Licensing/types'

interface IInternetLicenseProps {
    internetLicense: IInternetLicense
    Font: React.FC<any>
}

const InternetLicense: React.FC<IInternetLicenseProps> = ({ internetLicense, Font }) => {

    const breakpointMatch = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <React.Fragment>
            <Font> 
                Internet license
            </Font>
            <Font>
                {!breakpointMatch ? "Installation Id: " : "ID: "} {internetLicense.installationId}
            </Font>
        </React.Fragment>
    )
}

export default InternetLicense
