import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { styled } from '@material-ui/system';
import logo from '../../assets/logo-full.png'
import logoGrey from '../../assets/logo-full-grey.png'
import icon from '../../assets/icon.png'
import React from 'react'

const Separator = styled('span')(({ theme }) => ({
    width: "50px",
    height: "0px",
    border: "1px solid #9D9D9D",
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
    transform: "rotate(90deg)",
    marginLeft: "-20px",
    [theme.breakpoints.down(600)]: {
        marginLeft: "-25px",
    }
}));

const LogoImage = styled('img')({
    width: "auto",
    height: "100%",
});

interface ILogoProps {
    nav?: boolean
}

const Logo: React.FC<ILogoProps> = (props) => {

    const breakpointMatch = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

    const logoPicker = () => {
        if (breakpointMatch) {
            return (<LogoImage src={icon} alt="" />)
        }
        return (<LogoImage src={props.nav ? logoGrey : logo} alt="" />)
    }

    const getFontColor = (): string => {
        if (props.nav)
            return "white";
        return "#241F21";
    }

    return (
        <Box sx={{
            display: "inline-flex",
            alignItems: "center",
            minWidth: {
                xs: "227px",
                md: "400px"
            },
            height: "100%",
            width: "auto"
        }}>
            {logoPicker()}
            <Separator />
            <Box sx={{
                marginLeft: "-20px",
                display: "flex",
                flexDirection: "column"
            }}>
                <Typography sx={{
                    marginBottom: "-10px",
                    color: getFontColor(),
                    fontSize: {
                        xs: "1.3rem",
                        sm: "1.5rem"
                    },
                    fontWeight: "700",
                    letterSpacing: "0.4px"
                }}>Face Verification</Typography>
                <Typography sx={{
                    boxSizing: "border-box",
                    fontWeight: "300",
                    color: getFontColor(),
                    fontSize: {
                        xs: "0.9rem",
                        sm: "1.1rem"
                    },
                    letterSpacing: "0.4px"
                }}>Management</Typography>
            </Box>
        </Box>
    )
}

export default Logo
