import { Theme, useMediaQuery } from '@material-ui/core'
import React from 'react'
import { IDongle } from '../../Store/Licensing/types'

interface IDongleProps {
    dongle: IDongle
    Font: React.FC<any>
}

const Dongle: React.FC<IDongleProps> = ({ dongle, Font }) => {

    const breakpointMatch = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <React.Fragment>
            <Font>
                {!breakpointMatch ? "Dongle number: " : "ID: "} {dongle.dongleId}
            </Font>
            <Font>
                {!breakpointMatch ? "Dongle hwid: " : "HwID: "} {dongle.hardwareId.toUpperCase()}
            </Font>
            <Font>
                {!breakpointMatch ? "Dongle version: " : "Version: "} {dongle.dongleVersion}
            </Font>
        </React.Fragment>
    )
}

export default Dongle
