import { IconButton } from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import { SnackbarKey, useSnackbar } from 'notistack';
import { FC } from 'react';

interface SnackbarCloseButtonProps {
    snackbarKey: SnackbarKey
}

const SnackbarCloseButton: FC<SnackbarCloseButtonProps> = ( props ) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(props.snackbarKey)}>
      <IconClose />
    </IconButton>
  );
}

export default SnackbarCloseButton;