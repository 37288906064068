import { TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import { IUsage } from '../../Store/Licensing/types'


interface IUsageProps {
    usage: IUsage
}

const UsageRow: React.FC<IUsageProps> = ({ usage }) => {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell rowSpan={4}>
                    {usage.operation}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Remaining </TableCell>
                <TableCell>{usage.remaining}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Used </TableCell>
                <TableCell>{usage.used}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Limit </TableCell>
                <TableCell>{usage.limit}</TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default UsageRow
