import { Box, Chip, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Theme, Typography, useTheme } from '@material-ui/core'
import Select, { SelectChangeEvent } from '@material-ui/core/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    disableScrollLock: false,
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

interface IMultipleSelectProps {
    label: string,
    selectedValues: string[],
    values: string[],
    onSelectionChange: (event: SelectChangeEvent<string[]>) => void,
    inputLabel: string,
}

const MultipleSelect: React.FC<IMultipleSelectProps> = (props) => {
    const theme = useTheme();

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography sx={{ marginBottom: "-5px" }}>
                    {props.label}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <FormControl size="small" sx={{ width: "100%" }}>
                    <InputLabel>{props.inputLabel}</InputLabel>
                    <Select
                        fullWidth
                        multiple
                        value={props.selectedValues}
                        onChange={props.onSelectionChange}
                        input={<OutlinedInput label={props.inputLabel} />}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} sx={{ height: '23px' }}/>
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {props.values.map((name) => (
                            <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, props.selectedValues, theme)}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default MultipleSelect
