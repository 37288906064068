import { IMenuItem } from "../components/Navigation/MenuItem";

export const MenuItems: IMenuItem[] = [
    {
        name: "Settings",
        path: "/settings"
    },
    {
        name: "Subjects",
        path: "/subjects"
    },
    {
        name: "Operations",
        path: "/operations"
    },
    {
        name: "Licensing",
        path: "/licensing"
    },
];
