import Axios, { AxiosRequestConfig } from "axios";
import { getFullApiUrl } from "../../config/axios";
import { IOperation, IOperationFilter, LivenessMode, Status, OperationType } from "./types";
import qs from "querystring"

const convertToInLineArray = (params: any): string => {
    const filterNonNull = (obj: Object) => {
        return Object.fromEntries(Object.entries(obj).filter(([k, v]) => v));
    }
    return qs.stringify(filterNonNull(params))
};

export const getOperations = async (filter: IOperationFilter | undefined, offset?: number, size?: number) => {
    
    let statusToString = filter?.statuses.map(x => Status[x]);
    let operationTypeToString = filter?.operationTypes.map(x => OperationType[x]);
    let livenessModeToString = filter?.livenessModes.map(x => LivenessMode[x]);
    let config: AxiosRequestConfig = {
        params: {
            offset: offset,
            size: size,
            statuses: statusToString,
            operationTypes: operationTypeToString,
            livenessModes: livenessModeToString,
            ...filter,
        },
        paramsSerializer: convertToInLineArray
    }
    const response = await Axios.get<IOperation[]>(getFullApiUrl() + "/operations", config)
    return response.data
}

export const countOperations = async (filter: IOperationFilter | undefined) => {
    let statusToString = filter?.statuses.map(x => Status[x]);
    let operationTypeToString = filter?.operationTypes.map(x => OperationType[x]);
    let livenessModeToString = filter?.livenessModes.map(x => LivenessMode[x]);
    let config: AxiosRequestConfig = {
        params: {
            statuses: statusToString,
            operationTypes: operationTypeToString,
            livenessModes: livenessModeToString,
            ...filter,
        },
        paramsSerializer: convertToInLineArray
    }
    const response = await Axios.get<number>(getFullApiUrl() + "/operations/count", config);
    return response.data;
}

export const deleteBulkOperation = async (operations: IOperation[]) => {
    let operationIds: number[] = []
    operations.forEach((operation) => {
        operationIds.push(operation.id)
    })
    let config: AxiosRequestConfig = {
        params: {
            operationIds: operationIds,
        },
        paramsSerializer: convertToInLineArray
    }
    const response = await Axios.delete(getFullApiUrl() + "/operations", config);
    return response.data;
}

export const deleteOperation = async (operation: IOperation) => {
    let config: AxiosRequestConfig = {
        params: {
            id: operation.id,
        },
        paramsSerializer: convertToInLineArray
    }
    const response = await Axios.delete(getFullApiUrl() + "/operations/id/", config);
    return response.data;
}
