import React, { useState } from 'react'
import { ISetting } from '../../Store/Management/types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Input, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { setSetting } from '../../Store/Management/actions';
import { useSnackbar } from 'notistack';


interface IAddSettingsDialogProps {
    open: boolean,
    onClose: () => void
}

const AddSettingsDialog: React.FC<IAddSettingsDialogProps> = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const [settings, setSettings] = useState<ISetting[]>([{ key: "", value: "" }]);

    const addRow = () => {
        setSettings(prev => ([...prev, { key: "", value: "" }]));
    }

    const removeRow = (index: number) => {
        const rows = [...settings]
        rows.splice(index, 1);
        setSettings(rows);
    }

    const onValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, id, value } = event.target as HTMLInputElement;
        const index = parseInt(id);

        setSettings(prev => {
            if (name === "key") {
                prev[index].key = value;
            } else if (name === "value") {
                prev[index].value = value;
            }
            return prev;
        });
    }

    const handleSettingsAdd = async () => {
        try {
            for (var setting of settings) {
                await setSetting(setting.key, setting.value);
            }
            enqueueSnackbar("Settings saved successfully", { variant: 'info' });
            props.onClose();
        } catch (err: any) {
            enqueueSnackbar(err.message, { variant: 'error' });
        }
    }

    return (
        <Dialog
            maxWidth="md"
            open={props.open}
            onClose={props.onClose}
        >
            <DialogTitle>Add new settings</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Value</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {settings.map((x, i) => (
                            <TableRow key={Math.random().toString()}>
                                <TableCell>
                                    <Input
                                        id={i.toString()}
                                        name="key"
                                        size="small"
                                        defaultValue={settings[i].key}
                                        onChange={onValueChange}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Input
                                        id={i.toString()}
                                        name="value"
                                        size="small"
                                        defaultValue={settings[i].value}
                                        onChange={onValueChange}
                                    />
                                </TableCell>
                                <TableCell>
                                    {settings.length === 1 ? null :
                                        <IconButton
                                            aria-label="save setting"
                                            onClick={() => removeRow(i)}
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                    }
                                    {(settings.length - 1) === i ?
                                        <IconButton
                                            aria-label="save setting"
                                            onClick={addRow}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                        :
                                        null} </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table>
            </DialogContent>

            <DialogActions sx={{ gap: 1 }}>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button onClick={handleSettingsAdd} color="primary" variant="contained">Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddSettingsDialog
