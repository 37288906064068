import React, { useState } from 'react'
import { Button, Container, Grid, Paper, styled, TextField, Typography } from '@material-ui/core'
import { FieldsOrder, ISubjectsFilter, SubjectsFieldOrderBy } from '../../Store/Subjects/types';
import DateTimeRange from '../Misc/DateTimeRange';
import NumberRange from '../Misc/NumberRange';
import { useSnackbar } from 'notistack';

const StyledButton = styled(Button)({
    margin: "0 0.5rem"
});

interface IDateRange {
    from: Date | null,
    to: Date | null
}
interface INumberRange {
    from: number,
    to: number
}

interface ISubjectFilterProps {
    onFilterApply: (filter: ISubjectsFilter) => void
}

const SubjectFilter: React.FC<ISubjectFilterProps> = (props) => {

    const { enqueueSnackbar } = useSnackbar();

    const [insertDate, setInsertDate] = useState<IDateRange>({ from: null, to: null });
    const [qualityRange, setQualityRange] = useState<INumberRange>({ from: NaN, to: NaN });
    const [livenessScoreRange, setLivenessScoreRange] = useState<INumberRange>({ from: NaN, to: NaN });
    const [subjectId, setSubjectId] = useState('');
    const [subjectIdRegexValid, setSubjectIdRegexValid] = useState(true);

    const handleInsertDateChage = (source: "from" | "to", value: Date | null) => {
        switch (source) {
            case 'from':
                setInsertDate(prev => ({ ...prev, from: value }))
                break;
            case 'to':
                setInsertDate(prev => ({ ...prev, to: value }))
                break;
        }
    }

    const handleNumberRangeChange = (source: "from" | "to",
        setter: React.Dispatch<React.SetStateAction<INumberRange>>,
        value: number) => {
        switch (source) {
            case 'from':
                setter(prev => ({ ...prev, from: value }))
                break;
            case 'to':
                setter(prev => ({ ...prev, to: value }))
                break;
        }
    }

    const handleSubjectIdChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSubjectId(event.target.value)
        try {
            new RegExp(event.target.value);
        } catch(e) {
            setSubjectIdRegexValid(false)
            return;
        }
        setSubjectIdRegexValid(true)
    }

    const resetFilter = () => {
        setInsertDate({ from: null, to: null })
        setQualityRange({ from: NaN, to: NaN })
        setLivenessScoreRange({ from: NaN, to: NaN })
        setSubjectId("");
    }

    const onFilterApply = () => {
        let filter: ISubjectsFilter = {
            subjectId: subjectId,
            qualityGte: isNaN(qualityRange.from) ? null : qualityRange.from,
            qualityLte: isNaN(qualityRange.to) ? null : qualityRange.to,
            livenessScoreGte: isNaN(livenessScoreRange.from) ? null : livenessScoreRange.from,
            livenessScoreLte: isNaN(livenessScoreRange.to) ? null : livenessScoreRange.to,
            insertDateGte: insertDate.from?.toISOString(),
            insertDateLte: insertDate.to?.toISOString(),
            subjectFieldOrderBy: SubjectsFieldOrderBy.SUBJECT_ID,
            fieldsOrder: FieldsOrder.ASC
        }

        let error = false;
        if (filter.qualityGte && filter.qualityLte &&
            (filter.qualityLte < filter.qualityGte)) {
            enqueueSnackbar("Quality FROM threshold cannot be greater than TO", { variant: 'warning' });
            error = true;
        }
        if (filter.livenessScoreGte && filter.livenessScoreLte &&
            (filter.livenessScoreLte < filter.livenessScoreGte)) {
            enqueueSnackbar("Liveness FROM threshold cannot be greater than TO", { variant: 'warning' });
            error = true;
        }
        if (insertDate.from && insertDate.to &&
            (insertDate.to.getTime() < insertDate.from.getTime())) {
            enqueueSnackbar("Time range FROM threshold cannot be greater than TO", { variant: 'warning' });
            error = true;
        }
        if (error)
            return;
        props.onFilterApply(filter);
    }

    const submitFilter = () => {
        console.log("filter submited")
    }

    return (
        <Container maxWidth="lg" sx={{ marginBottom: "1rem" }}>
            <Paper elevation={10}>
                <Grid container onSubmit={submitFilter} spacing={1} sx={{ padding: "1rem" }}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography sx={{ marginBottom: "-5px" }}>
                                    Subject ID
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    spellCheck="false"
                                    fullWidth
                                    label="Regex"
                                    size="small"
                                    value={subjectId}
                                    onChange={(event) => handleSubjectIdChange(event)}
                                    error={!subjectIdRegexValid}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} md={6} >
                        <DateTimeRange
                            label="Insert date range"
                            from={insertDate.from}
                            to={insertDate.to}
                            onFromDateChange={(value) => handleInsertDateChage("from", value)}
                            onToDateChange={(value) => handleInsertDateChage("to", value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <NumberRange
                            label="Quality threshold"
                            from={qualityRange.from}
                            to={qualityRange.to}
                            onFromNumberChange={(value) => handleNumberRangeChange("from", setQualityRange, value)}
                            onToNumberChange={(value) => handleNumberRangeChange("to", setQualityRange, value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <NumberRange
                            label="Liveness score threshold"
                            from={livenessScoreRange.from}
                            to={livenessScoreRange.to}
                            onFromNumberChange={(value) => handleNumberRangeChange("from", setLivenessScoreRange, value)}
                            onToNumberChange={(value) => handleNumberRangeChange("to", setLivenessScoreRange, value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ padding: "1rem 2rem", display: "flex", justifyContent: "flex-end", marginTop: "27px" }}>
                        <StyledButton
                            onClick={() => resetFilter()}
                        >
                            Reset
                        </StyledButton>
                        <StyledButton
                            onClick={() => onFilterApply()}
                            variant="contained"
                        >
                            Filter
                        </StyledButton>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}

export default SubjectFilter